import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const CallToAction = () => {

    const { language } = useContext(LanguageContext);

    const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: 'User Interaction',
          action: 'Clicked A Btn Link',
          label: linkText,
        });
      };

    return(
        <div className='lead-gen-mini'>
            <div className='lead-gen-mini-main'>
                <div className='lead-gen-mini-heading'>
                    <h2>{translations[language].callToAction}</h2>
                </div>
                <div className='lead-gen-mini-btn'>
                    <Link to='/membership' onClick={handleLinkClick}>{translations[language].callToActionLinkText}<i className='bx bxs-hand-right'></i></Link>
                </div>
            </div>
        </div>
    );
}

export default CallToAction;