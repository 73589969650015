import React, { useContext } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const AboutBrief = () => {
    const { language } = useContext(LanguageContext);

    const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: 'User Interaction',
          action: 'Clicked A Btn Link',
          label: linkText,
        });
      };

    return (
        <>
            <div className='about-brief'>
                <div className="about-brief-content">
                    <div className='about-brief-text'>
                        <h2>{translations[language].aboutBriefHeading}</h2>
                        <p>{translations[language].aboutBriefParagraph}</p>
                    </div>
                    <div className='about-brief-btn'>
                        <Link to='/about-us' onClick={handleLinkClick}>{translations[language].aboutBriefLinkText}</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutBrief;