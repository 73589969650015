import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const MembershipIntro = () => {
    const { language } = useContext(LanguageContext);

    const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: "User Interaction",
          action: "Clicked A Btn Link",
          label: linkText,
        });
      };

    return(
        <>
            <div className="membership-container">
                {/* Left Section */}
                <div className="membership-left">
                    <div className="img-container">
                        <img src="assets/members_unite.webp" alt={translations[language].membershipIntroAltText} />
                    </div>
                </div>
                
                {/* Right Section */}
                <div className="membership-right">
                        <h2>{translations[language].membership}</h2>
                    <div className="membership-paragraph">
                        <p>{translations[language].membershipIntroParagraph}</p>
                    </div>

                    {/* Buttons Section */}
                    <div className="membership-buttons">
                        <Link to="/membership" id="member_info" onClick={handleLinkClick}>
                            {translations[language].membershipInfoLinkText}
                        </Link>
                        <Link to="/membership" id="member_apply" onClick={handleLinkClick}>
                            {translations[language].membershipApplyLinkText}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MembershipIntro;