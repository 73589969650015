import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const RegionalGoverners = () => {
    const { language } = useContext(LanguageContext);

    return (
        <div className="regional-governers-container">
            <div className="regional-governers-intro">
                <h2>{translations[language].regionalGovernersHeading}</h2>
            </div>
            <div className="regional-governers-cards">
                <div className="region-card">
                    <h3>{translations[language].regionNameOne}</h3>
                    <p>{translations[language].presidentMalePosition}: <span>Sawa Wabomba</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Roymark Barasa</span></p>
                    <p>{translations[language].treasurerFemalePosition}: <span>Roseline Osundwa</span></p>
                    <h4>{translations[language].flotantHeading}</h4>
                    <p className="fw-700">Dr. Costa Kaburu - <span className="fw-500">{translations[language].establishment1}</span></p>
                    <p className="fw-700">Susan Geke - <span className="fw-500">{translations[language].schoolTypeNine}</span></p>
                </div>
                <div className="region-card alternate-gradient">
                    <h3>{translations[language].regionNameTwo}</h3>
                    <p>{translations[language].presidentMalePosition}: <span>Jack Mukosi</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Susan Chepng'etich</span></p>
                    <p>{translations[language].treasurerPosition}: <span>Eric Muange</span></p>
                    <h4>{translations[language].flotantHeading}</h4>
                    <p className="fw-700">Dorcas Nabwoba</p>
                    <p className="fw-700">Festus Musyoka</p>
                </div>
                <div className="region-card">
                    <h3>{translations[language].regionNameThree}</h3>
                    <p>{translations[language].presidentMalePosition}: <span>Masambu Wataka</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Yvonne Mukodo</span></p>
                    <p>{translations[language].treasurerPosition}: <span>Silas Muhandia</span></p>
                    <h4>{translations[language].flotantHeading}</h4>
                    <p className="fw-700">Fiona Ketoyo - <span className="fw-500">{translations[language].schoolTypeSeven} / {translations[language].schoolTypeEight}</span></p>
                    <p className="fw-700">Margaret Thang'wa - <span className="fw-500">{translations[language].schoolTypeNine}</span></p>
                </div>
                <div className="region-card alternate-gradient">
                    <h3>{translations[language].regionNameFour}</h3>
                    <p>{translations[language].presidentPosition}: <span>Maureen Macharia</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Daniel Waka</span></p>
                    <p>{translations[language].treasurerPosition}: <span>Dennis Nyongesa</span></p>
                </div>
                <div className="region-card">
                    <h3>{translations[language].regionNameFive}</h3>
                    <p>{translations[language].presidentPosition}: <span>Lorène Mukasia</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Noraline Lilliane Owino</span></p>
                    <p>{translations[language].treasurerPosition}: <span>Zebedee William Wekesa</span></p>
                    <h4>{translations[language].flotantHeading}</h4>
                    <p className="fw-700">Cynthia Okumu</p>
                    <p className="fw-700">Nancy Motanya</p>
                </div>
                <div className="region-card alternate-gradient">
                    <h3>{translations[language].regionNameSix}</h3>
                    <p>{translations[language].presidentPosition}: <span>Maureen Wekesa</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Franklin Ondary</span></p>
                    <p>{translations[language].treasurerPosition}: <span>Dickens Lugaga</span></p>
                </div>
                <div className="region-card">
                    <h3>{translations[language].regionNameSeven}</h3>
                    <p>{translations[language].presidentPosition}: <span>Faith Osino</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Samson Otieno</span></p>
                    <p>{translations[language].treasurerFemalePosition}: <span>Rita Ayako</span></p>
                    <h4>{translations[language].flotantHeading}</h4>
                    <p className="fw-700">Barnabé Odhiambo</p>
                    <p className="fw-700">Glender Ingasiani</p>
                </div>
                <div className="region-card alternate-gradient">
                    <h3>{translations[language].regionNameEight}</h3>
                    <p>{translations[language].presidentMalePosition}: <span>Mwabonje Mwangudza</span></p>
                    <p>{translations[language].secretaryPosition}: <span>Janet Rose</span></p>
                    <p>{translations[language].treasurerFemalePosition}: <span>Sheilla Moraa</span></p>
                </div>
            </div>
        </div>
    );
};

export default RegionalGoverners;