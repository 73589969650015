export const translations = {
  en: {
    home: "Home",
    about: "About",
    culture: "Our Culture",
    team: "Our Team",
    blog: "Blog",
    events: "Events",
    upcomingEvents: "Upcoming Events",
    pastEvents: "Past Events",
    resources: "Resources",
    membership: "Membership",
    news: "News",
    contact: "Contact",
    gallery: "Gallery",
    faqs: "FAQs",
    constructionNotice: "This Page Is Under Construction",
    introductionText:
      "Welcome to The Kenya Association of Teachers of French! We are dedicated to promoting the teaching and learning of French throughout the country. Whether you are a teacher, student, or passionate about the French language, our platform is your ideal resource to discover, learn, and share. Join us to explore quality educational resources, engaging events, and professional development opportunities.",
    contactButton: "Contact us",
    aboutButton: "Learn more...",
    altText:
      "An illustration representing the dynamic teaching of French by the Association of French Teachers of Kenya",
    rotatingHeaderText: [
      "Quality education",
      "Innovative teaching resources",
      "Engaged community",
    ],
    introImageAltTexts: [
      "The seven members of the team that won the elections, the current members of the KATF Executive Board of Directors",
      "Participants at St. Peters Mumias Boys during the second round session by one of the speakers Ms. Fiona Ketoyo (Katf, 2024).",
      "Inauguration of the resource center at PC Kinyanjui TVET by the French Ambassador",
      "French teacher and representative of the KUPPET teachers' union (left) with General Secretary Mr. Enock Webi (middle) (Katf, 2024)",
    ],
    aboutBriefHeading: "Brief history",
    aboutBriefParagraph:
      "Founded in 1961, KATF - an acronym meaning Kenya Association of Teachers of French in English - is a collective of FLE teachers across the different regions of the country. It has more than 100 members who pay their dues each year, including 7 representatives of the executive board and 7 others in the regions.",
    aboutBriefLinkText: "More about KATF",
    achievementsHeading: "KATF achievements",
    achievementOneHeading: "Webinars",
    achievementOneDescription:
      "Réseau Canopé Webinars offered expert insights, practical tools, and interactive learning.",
    achievementTwoHeading: "Opening a resource centre",
    achievementTwoDescription:
      "The opening of the 22nd resource center at Kinyanjui TVET.",
    achievementThreeHeading: "Trainings",
    achievementThreeDescription:
      "Virtual training at Kenyatta University. In-person training at the University of Nairobi.",
    achievementFourHeading: "The DEFIK project",
    achievementFourDescription:
      "The DEFIK project is on the right track. A promising future",
    achievementExploreLinkText: "Explore more",
    membershipIntroParagraph:
      "Joining the Kenya Association of Teachers of French (KATF) opens the door to a vibrant community of educators passionate about promoting the French language in Kenya. As a member, you will have access to exclusive resources, professional development opportunities, and a platform to share best practices with fellow teachers.",
    membershipInfoLinkText: "Membership information",
    membershipApplyLinkText: "Apply for membership",
    partnerRotatingHeadings: [
      "Collaborating for a greater impact",
      "Building global connections",
      "Strengthening french educational networks",
    ],
    partnerIntroParagraph:
      "Our partners play a crucial role in advancing the mission of the Kenya Association of Teachers of French. Through strategic collaborations and shared resources, we are able to enhance the teaching and learning of French across the region. Together, we build bridges between cultures, foster educational excellence, and create opportunities for students and educators alike. We are proud to work alongside esteemed institutions, organizations, and communities that share our vision for a brighter, more connected future.",
    francophonieLinkText: "Check out Francophonie au Kenya",
    francophonieParagraph:
      "Francophonie au Kenya brings together all the cooperation activities for French carried out by the cooperation and cultural action service of the French Embassy and its associative and institutional partners. Join the new WhatsApp group of La Francophonie au Kenya today to benefit from all our news announcements, webinars, events, training, competitions and much more.",
    francophonieWhatsappLinkText: "Join WhatsApp group",
    francophonieHeading1: "Study & work in France",
    francophonieHeading2: "French teachers networks",
    francophonieHeading3: "Francophone projects",
    mainEventDetails: "November 29, 2024 | Alliance Française, Mombasa, KE",
    mainEventTitle: "International French Teachers Day (JIPF)",
    mainEventBenefit:
      "All champions, all champions, bearers of the francophone flame",
    mainEventParagraph:
      "All French teachers were celebrated on November 23rd at Maseno University. The celebrations continued on November 29th at the Alliance Française in Mombasa. French Teachers' Day brings together French teachers and also those who teach in French in bilingual training, all over the world.",
    eventLinksText: "Learn more",
    eventTwoDetails:
      "February 27, 2025 | Alliance Française, Monrovia St, Nairobi, KE",
    eventTwoTitle: "Pedagogy workshop",
    eventTwoBenefit: "Enhance your teaching strategies",
    eventTwoParagraph:
      "Discover innovative teaching techniques and methodologies at the French Language Pedagogy Workshop.",

    eventThreeDetails:
      "March 17, 2025 | Safari Park Hotel, Thika Rd, Nairobi, KE",
    eventThreeTitle: "Cultural immersion seminar",
    eventThreeBenefit: "Deepen your cultural understanding",
    eventThreeParagraph:
      "Engage in an enriching cultural immersion experience. Explore the rich heritage and traditions of Francophone countries.",

    eventFourDetails:
      "July 23, 2025 | Kenyatta International Convention Centre, Harambee Ave, Nairobi, KE",
    eventFourTitle: "Digital tools",
    eventFourBenefit: "Integrate technology into your lessons",
    eventFourParagraph:
      "Join us for a hands-on workshop focused on leveraging digital tools in language education to enhance student engagement.",
    publicationsHeading: "Our publications",
    publicationOneTitle:
      "Report on the teaching of French in Kenya: History, current status and prospects",
    publicationOneExcerpt:
      "The transmission of French plays an essential role in Kenya between linguistic, cultural and economic exchanges...",
    publicationTwoTitle:
      "Report of the general secretary about the revision day at St. Peter’s Boys’ school",
    publicationTwoExcerpt:
      "The Association organized a review day which took place on July 21, 2024 at St. Peter’s Boys High School...",
    publicationThreeTitle:
      "BLOWING By Ashioya Boniface: The KATF elections wind is blowing across the country",
    publicationThreeExcerpt:
      "Elections come, elections go, years go by, and the wind of KATF elections blows across the country...",
    publicationFourTitle:
      "Methodology in teaching and learning writing skills in French in Kenya",
    publicationFourExcerpt:
      "Choosing the methodology to use in teaching French is a big challenge and requires extra skills for both teachers...",
    callToAction: "Empower your french teaching journey with us",
    callToActionLinkText: "Join the KATF community",
    aboutHeadingText: [
      "Empowering french education in Kenya",
      "Building bridges through language & culture",
      "Leading the charge for a bilingual future",
    ],
    aboutUsHeading: "About us",
    aboutUsParagraph:
      "The Kenya Association of Teachers of French (KATF) was founded in 1961 by French teachers from Catholic Church schools including: Loreto, Mang'u High School and St. Mary's School. They were later joined by other schools such as Duke of York (Lenana School), Prince of Wales (Nairobi School) among others. In 1964, after independence, some schools in Kenya taught French as a foreign language and special subject. On 21st June 1968, KATF was officially registered with the Civil Registry Office with the assistance of the then Deputy Registrar of Societies, Mr. ROBERT DUNCAN McLAREN, its Certificate of Registration being R. 4711. In 1971, France and Kenya signed a cultural cooperation agreement. The agreement provided for the establishment of exchanges of researchers, students and teachers between the two countries. KATF is currently an association of teachers of French from across the different regions of the country. It has more than 150 active members who pay their dues each year. It is managed by seven members of the national executive board and seven regional representatives.",
    aboutUsMoreText: "Read more",
    aboutUsLessText: "Read less",
    missionHeading: "Our mission",
    visionHeading: "Our vision",
    valuesHeading: "Our values",
    missionStatement:
      "Strengthen the capacities of the French teachers in Kenya so that they can impact and influence the world around them in a positive way.",
    visionStatement:
      "A competent, well-motivated and internationally competitive French teacher.",
    valuePoints:
      "Information. Professionalism. Inclusivity. Respect. Team Spirit. Transparency.",
    valueOne: "Information",
    valueTwo: "Professionalism",
    valueThree: "Inclusivity",
    valueFour: "Respect",
    valueFive: "Team spirit",
    valueSix: "Transparency",
    objectivesHeading: "Our objectives",
    objectives: [
      "Promote the teaching/learning of French in Kenya at all levels of the Kenyan education system.",
      "Promote the teaching/learning of the French language in Kenya.",
      "Work to expand and improve the quality of the French language in Kenya.",
      "Boost the process of modernizing French teaching/learning strategies.",
      "Arouse teachers’ interest in scientific research and didactic innovation.",
      "Facilitate professional debates among French teachers in Kenya; Contribute to the stimulation of professional dialogue and pedagogical reflection.",
      "Propose forums for the exchange of ideas between Kenyan and foreign specialists in the field of French language teaching, with a view to making the community of teachers in Kenya aware of the latest advances in theory and practice in this dynamically developing field.",
      "Encourage the pooling of resources, experiences, and pedagogical research for mutual enrichment.",
      "Defend before competent bodies and institutions the application of a balanced language learning policy in accordance with the national specialty, regional and global trends and which can guarantee an adequate share in the overall policy of teaching the French language in Kenya.",
      "Consolidate the interest and motivation of learners with a view to a better understanding of the civilizational elements specific to the French-speaking area.",
      "Promote intercultural dialogue and human exchanges in the French-speaking contex.",
      "Promote, support, and maintain good relations with the Ministry of Education, the directors of schools where French is taught, universities and colleges, the Alliance Française, the French Embassy, the APFA-OI; the International Federation of French Teachers (FIPF), the Standing Committee for the Francophonie, and other organizations interested in the activities of the Association.",
      "Ensure institutional recognition of the qualifications of teachers in the fields of FLE as well as in those of organizations that value French as a working language.",
      "Work in cooperation and closely with other French-speaking, professional, educational, or Francophile organizations in the promotion of the French language, including the Ministry of Education, the French Embassy, Alliance Française, the Association of French Teachers in Africa and the Indian Ocean (APFA-OI), the International Federation of French Teachers (FIPF), the Permanent Committee for the Francophonie (CPF), and schools where French is taught.",
    ],
    presidentPosition: "President",
    presidentDescription:
      'Dr. Mugalla is a PhD. holder from Kenyatta University,  and has 26 years of professional experience. She is the author of the "Au Sommet" series and has won multiple Teacher of the Year awards. She has also contributed to the French language curriculum designs under CBC and led French language initiatives, including organizing the "Journée de la Francophonie". She is a lecturer at various universities and has been an examiner for DELF and KCSE. Additionally, she is active in mentorship and educational leadership.',
    deputyPresidentPosition: "Vice President",
    deputyPresidentDescription:
      "With over twenty years of experience as a dedicated member of KATF, Mr. Luke Munyendo has been instrumental in promoting French learning both inside and outside the classroom. He holds a Bachelor of Education in French and Geography from Maseno University (2005) and has undergone extensive training, enabling him to serve as an examiner for Oral French at KNEC and a trainer and judge at the Kenya Music Festival. As President of KATF for the coastal region, he has established strong collaborations with the Alliance Française Mombasa, the French Embassy, and the Ministry of Education. Mr. Munyendo is committed to broadening French teaching through partnerships, workshops, and exchange programs.",
    secGenPosition: "Secretary General",
    secGenDescription:
      "Mr. Enock Webi, has been an active member of KATF for 17 years. With a strong background in Diplomacy and International Relations, he holds a Master’s degree and is pursuing his Doctorate at Masinde Muliro University of Science and Technology. He has significantly contributed to the growth of French language education, advocating for resource centers, organizing workshops, and coordinating French events. Additionally, he is a published author and content developer for Zeraki French online lessons.",
    assSecGenPosition: "Deputy Secretary General",
    assSecGenDescription:
      "Ms. Eshery Munala, has been a teacher for 26 years and a member of KATF since the year 2000. She is currently the principal of C.G.H.U Secondary School in Nairobi and has previously held leadership roles at schools in Kajiado and Narok. A trainer of trainers with Kenyatta University, Ms. Munala is a national adjudicator and trainer for drama and music festivals. She has authored a French revision book and published research on ICT in education. She is also a part-time lecturer and French oral examiner, pursuing a Ph.D. in Education Administration from the University of Nairobi.",
    treasurerPosition: "Treasurer",
    treasurerDescription:
      "Mr. Timothy Muthoka Kyeva, an active member of KATF, has played a significant role in French language education, earning recognition as the Best Teacher of the Year (TOYA) in Nairobi in 2019. He is also an examiner-corrector with KNEC and FEI, further demonstrating his expertise in the field. Mr. Muthoka currently serves at KATF as Treasurer, with a focus on collaborating with the national office to secure financial and material resources for ongoing projects, as well as initiating new ones to help KATF achieve its mission and vision.",
    flottant1Description:
      "Dr. Nuru Kyalo holds a Bachelor's in Education and a Master of Arts from Kenyatta University, along with a PhD in Language Sciences (Semiotics) from the University of Limoges and Maseno University, sponsored by the French Embassy in Kenya. She has been a lecturer in the Department of French and Other Foreign Languages at Maseno University since 2008. Dr. Kyalo also serves as the Chairperson and CEO of the Association of Francophone Kenyan Writers (AFKW), where she mentors Kenyan francophone writers. Additionally, she is the Director of La Collection poésie francophone kényane, an annual Kenyan poetry anthology.",
    flottant2Description:
      "Mr. Robert Matunyo has been teaching French as a Foreign Language (FLE) for over five years, during which he has achieved impressive results with his candidate classes at KNEC. As an active member of KATF, he has also served as an examiner for French at KNEC. In 2020, he was recognized by his principal for making French the most improved subject in the school, even before his formal employment by the TSC. With his dedication and passion, Mr. Matunyo aims to serve as a floating member of KATF, representing young teachers and working closely with the national office to unite and support special groups, contributing to the association's mission and vision.",
    executiveHeading: "Executive members",
    executiveParagraph:
      "Meet the dedicated leaders of The Kenya Association of Teachers of French, committed to advancing French language teaching / learning and driving the Association's goals.",
    structureHeading: "The KATF structure",
    structureParagraph:
      "The structure of KATF is organized in a hierarchical framework, starting with the General Assembly at the top. The General Assembly is the supreme collective body of the Association. It is composed of all active members of the Association. The Executive Board of Directors, elected by the General Assembly, consists of the President, Vice President, Secretary General, Deputy Secretary General, Treasurer, and two additional members referred to as Membres Flottants. Under the Executive Board of Directors are the Regional Governing Bodies. Each Regional Governing body consists of a President, Secretary, Treasurer and two Membres Flottants. The Regional Governing Bodies ensure the implementation of KATF's initiatives across the eight regions; Nairobi, Nyanza, Central, Eastern, Coast, Western, North Rift and South Rift.",
    regionalGovernersHeading: "Regional Governing Bodies",
    regionNameOne: "Nairobi region",
    regionNameTwo: "Eastern region",
    regionNameThree: "Western region",
    regionNameFour: "Central region",
    regionNameFive: "Nyanza region",
    regionNameSix: "Rift Valley North region",
    regionNameSeven: "Rift Valley South region",
    regionNameEight: "Coast region",
    presidentMalePosition: "President",
    secretaryPosition: "Secretary",
    treasurerFemalePosition: "Treasurer",
    flotantHeading: "Flottants",
    teacherNumbersHeading: "Here are our current numbers",
    teacherNumbersParagraph:
      "Discover the strength of our community with a dedicated network of French teachers across Kenya, united in their passion for language education. Here are our current numbers.",
    teachersTableHeading1: "No.",
    teachersTableHeading2: "Level of teaching",
    teachersTableHeading3: "Number of teachers",
    establishment1: "Primary",
    establishment2: "Secondary",
    establishment3: "Technical Training Institutes (TVET)",
    establishment4: "National Polytechnic Schools",
    establishment5: "Normal schools (TTC)",
    establishment6: "Universities",
    aboutOutroHeading:
      "Join us in shaping the future of french language teaching and learning",
    aboutOutroLinkText: "Become a member",
    conferenceEventCategory: "Conferences",
    webinarEventCategory: "Webinars",
    workshopEventCategory: "Workshops",
    frenchDaysEventCategory: "French Days",
    otherEventCategory: "Other Events",
    webConferenceDateRange: "August 21-23, 2024",
    webConferenceTitle: "KATF Web Conference",
    webConferenceDescription: {
      title: "Key Questions of the Web-Conference",
      sections: [
        {
          heading: "Day One",
          questions: [
            "KATF is celebrating its 63rd anniversary this year, as an individual, what have you done for your association during these years?",
            "French is no longer the language of Molière, it is a language of your own, what value do you attach to your language?",
          ],
        },
        {
          heading: "Day Two",
          questions: [
            "The Francophonie in Kenya brings together diverse movements, what is your personal commitment as an activist for the progress of the French language in Kenya within and/or outside the school ecosystem?",
            "The digital age is with us and is quickly establishing its roots in the teaching of the French language. What would you do to remain relevant in the future?",
            "Is there a future for Kenyan Francophone literature?",
            "How can we federate and promote all the actions of Francophone movements in Kenya?",
            "Are you taking full advantage of the many actions and projects implemented by our partner the French Embassy in Kenya?",
            "CBC is a skills-based approach and requires creativity on the part of the teacher. Are we ready to teach CBC?",
            "From 8-4-4 to CBC, are there challenges in teaching French caused by the change in the education system? How are we going to face these challenges?",
          ],
        },
        {
          heading: "Third Day",
          questions: [
            "There is a wealth of resources on the CRKF online. Do you already have access to the resources on CRKF?",
            "The (physical / virtual) classroom space is evolving, and you?",
            "What place for poems and plays in a classroom?",
            "How to harmonize the experience in the FLE classroom?",
            "You are a FLE teacher and you train great personalities in the world. You take care of other people's children for years. Who takes care of you?",
            "Are you investing in yourself?",
            "What does “Moving Forward” mean to you?",
            "What other skills do you have? How can you promote them?",
            "What other careers fascinate you?",
            "Are you visible to the world?",
            "What are the needs of TVETs and “colleges” for the progress of French at these levels?",
            "Are university professors ready to welcome learners from the new education system in 2029?",
            "Will CBC students have the right entry level to continue their courses in French at university?",
            "Will teacher training be sufficient at the TTC and university levels?",
            "What interventions should be put in place to prepare for the future of French at university?",
          ],
        },
      ],
      conclusion: [
        "As a member of KATF, your answers to all these questions determine the future of the French language.",
        "It is the French language that sustains the ecosystem of the Francophonie in Kenya. It is the source of our livelihood. We must attach a deeper value to the French language so that we can campaign for its progress and its better future.",
        "Decide today what you are going to do for the French language; either in the classroom or outside the classroom, for KATF, elsewhere in the various professional fields, and even for yourself to ensure its future in Kenya.",
        "The KATF web conference concluded successfully, with attendees benefitting from the fruitful exchanges. Following this, KATF held consultative meetings with primary and secondary school teachers, educators from tertiary institutions – TTC and TVET – and those from the university level. These meetings aimed to better understand the specific needs of each group of educators.",
        "KATF then committed to addressing the unique requirements of teachers across all educational levels. Dr. Nuru Kyalo, alongside KATF, the Francophonie movements in Kenya, and the French Embassy, expressed their dedication to this mission, encouraging others to join in their efforts.",
        "A call was made to shift perspectives, urging educators and stakeholders to ask, 'What can I do for KATF?' instead of 'What does KATF do for me?'",
        "On behalf of the organizing committee, KATF regional offices, and the KATF executive board, Dr. Nuru Koki Kyalo extended her sincere gratitude to all participants for their active involvement and valuable contributions throughout the conference.",
        "A special acknowledgment was also made to the French Embassy in Kenya and Somalia, represented by Mr. Bernard Clouteau, for their unwavering support. The Embassy was recognized as an indispensable partner in KATF's mission to advance the French language towards a brighter future, and their efforts were deeply appreciated.",
        "The KATF web conference concluded with a heartfelt closing prayer, bringing the event to a meaningful close.",
      ],
    },
    languageWorkshopDateRange: "April 2024, 5 Days",
    languageWorkshopTitle: "Workshop for The Design of 15 Language Modules",
    languageWorkshopLocation: "Naivasha, Kenya.",
    languageWorkshopDescription: `Abstract
      A production workshop of 15 modules of one hour each in linguistic reinforcement. This training took place with the aim of strengthening the linguistic level of teachers in the creation of digital resources in FLE
      Dates: April 2024
      Duration: 5 days
      Location: Naivasha, Kenya
      Number of participants: 17
      Speaker/facilitator: Canopé Network mediator`,
    fosWorkshopDateRange: "April 2024, 5 Days",
    fosWorkshopTitle: "Training in Educational Scriptwriting in FOS",
    fosWorkshopLocation: "Naivasha, Kenya.",
    fosWorkshopDescription: `Abstract
      A training in the production of digital resources in the context of French for Specific Purposes. This training was designed to strengthen the skills of participants in the creation and use of digital tools adapted to the teaching of French in specific contexts.
      Dates: April 2024
      Duration: 5 days
      Location: Naivasha, Kenya
      Number of participants: 18
      Speaker/facilitator: Réseau Canopé mediator
      `,
    electionsDateRange: "Saturday April 27",
    electionsTitle: "KATF Elections",
    electionsLocation: "Nakuru High School, Nakuru.",
    electionsDescription: `This year, the association prepared to hold a remarkable event - an exceptional general meeting during which members would vote for new candidates in order to set up a new office to serve its members.
      CALL FOR APPLICATIONS All positions having been declared vacant, a call for applications was made on April 1st to interested candidates. The persons mentioned should respect the guidelines provided by the electoral committee of the association including: ✔accessibility to the constitution would only be granted to active members.
      ✔the deadline for submitting and receiving applications would be between April 1st and 6th.
      ✔all official communication of the association would be done through the elected officials of KATF (national and regional offices) EXTRAORDINARY CAMPAIGNS Following complaints associated with the availability of the constitution and membership status by some candidates gathered with other members, intense debates about the organizers including the election leaders and the indifference of some against others according to their points of view towards the process itself, the first candidate finally launched his campaign and that of his team. Mr. Jean BARON- the aspiring treasurer from the 1st team under the slogan << OTHER TIMES, OTHER RHYTHMS >>, opened the ball of his campaigns! The leader of his team was Mrs. Kate SAKARI- the aspiring president- a position for which she was just as qualified as her opponent from the 2nd team, Dr. Léonorah LUTIALI. So, not wanting to be left behind, the 2nd team, under the slogan << TEAM FOR A BRIGHT FUTURE >>, followed suit. Mr. Enock WEBI - its aspiring Secretary General, had to introduce all 7 members of the team to potential voters.
      It was a marathon race between the two strong camps, each challenging its opponent with well-studied strategic plans, visions and programs. Rumors and propaganda sometimes worked to the advantage or disadvantage of the opponents, a strategy that did not bode well for most members. But despite what some consider good or bad politics, the supporters of the candidates, leaving nothing to chance, each campaigned for their favorite contender every second... minute... instant... And as some members of the collective would say, it got really heated!!!
      THE DAY WHEN… Nakuru High School, Saturday April 27th at 9:00 am, the big day finally arrived - a long-awaited general assembly! Teachers came in large numbers to attend this exceptional event. A day filled with numerous and important activities, the highlight was the election of the new members of the executive board. Once the various activities were completed, it was time to vote. After about sixty minutes, all registered voters had voted and around 6:00 pm, the results were finally announced... By coincidence or not, the 7 members of the 2nd camp, led by Dr. Léonorah LUTIALI, were all elected! They were the ones who would now sit on the executive board or even the national board for the next 2 years. It was wonderful to see that despite their differences of opinion during the campaigns, it was a moment of unity coupled with celebrations for everyone, both teams understood - winner and loser. We had all won. KATF won.
      <<LONG LIVE KATF AND CONGRATULATIONS TO THE TEAM FOR A BRIGHT FUTURE!>>`,
    openingResourceDateRange: "August 2024",
    openingResourceTitle:
      "The Opening of The 22nd Resource Center at Kinyanjui TVET",
    openingResourceLocation: "At Kinyanjui TVET",
    openingResourceDescription:
      "The opening of the 22nd Kinyanjui TVET Resource Center marks a significant milestone in the advancement of French language learning and teaching in Kenya. This new center will play a pivotal role in providing much-needed resources for teachers and students in Technical and Vocational Education and Training (TVET) institutions. By offering innovative tools and access to French language materials, the center aims to enhance the skills of learners, preparing them for future academic and professional endeavors in Francophone contexts. This initiative also supports the growth of French as a key language in Kenya's evolving education system, fostering cultural exchange and creating new opportunities for students and educators alike.",
    frenchTeachersDayDateRange: "November 23 & 29, 2024",
    frenchTeachersDayLocation: "Maseno University, Maseno, KE & Alliance Française, Mombasa, KE",
    frenchTeachersDayDescription:
      'All French teachers were celebrated on November 23rd at Maseno University and on November 29th at the Alliance Française in Mombasa!!!!! This year\'s theme was: "All champions, all champions, bearers of the Francophone flame". This year the celebration was BIGGER and MORE INTERESTING!!! Interesting activities were organized for French teachers in Kenya!!! There were limited places for in-person participation in Mombasa. Do you have any great ideas to make the celebration more enjoyable for you next year??? Send your ideas to KATF through the Secretary General.',
    frenchTeachersDayLinkText: "More about JIPF",
    pedagogyWorkshopDateRange: "February 27, 2025",
    pedagogyWorkshopTitle: "Pedagogy Workshop",
    culturalImmersionDateRange: "March 17, 2025",
    culturalImmersionTitle: "Cultural Immersion Webinar",
    culturalImmersionDescription:
      "Immerse yourself in a transformative cultural experience as you explore the vibrant heritage and rich traditions of Francophone countries at our Cultural Immersion Workshop. This enriching journey offers participants a unique opportunity to engage with the music, art, cuisine, and customs that define the diverse Francophone world. Through interactive sessions, discussions, and activities, you will gain a deeper appreciation for the cultural nuances that shape the French language and its speakers. Whether you are an educator, student, or cultural enthusiast, this experience promises to broaden your perspectives, foster global understanding, and inspire a greater connection to the Francophone community. Step beyond language and discover the heart and soul of French-speaking cultures in a way that only true immersion can offer.",
    pedagogyWorkshopLocation: "Alliance Française, Monrovia St, Nairobi, KE",
    pedagogyWorkshopDescription:
      "Discover innovative teaching techniques and methodologies at the French Language Workshop on pedagogy, designed to empower educators with modern tools for effective language instruction. This workshop will delve into the latest pedagogical trends, exploring interactive approaches that engage students in dynamic and immersive learning experiences. Participants will have the opportunity to collaborate with fellow educators, share best practices, and gain hands-on experience with new teaching technologies. Whether you're looking to enhance your classroom strategies or stay updated with evolving education standards, this workshop offers valuable insights and resources to help you excel in teaching French as a second language.",
    digitalToolsDateRange: "August 10, 2025",
    digitalToolsTitle: "Digital Tools Conference",
    digitalToolsLocation:
      "Kenyatta International Convention Centre, Harambee Ave, Nairobi, KE",
    digitalToolsDescription:
      "Join us for an exciting, hands-on workshop designed to explore the latest digital tools and their powerful role in transforming language education. In this interactive session, you will learn how to integrate innovative technology into your teaching practices to boost student engagement and enhance learning outcomes. From dynamic language apps and virtual classrooms to cutting-edge assessment tools, you'll discover practical strategies for creating an immersive and engaging learning environment. Whether you're a seasoned educator or new to incorporating digital resources, this workshop will equip you with the skills and confidence to harness the full potential of digital tools in the modern language classroom. Elevate your teaching, inspire your students, and stay ahead in the evolving world of education.",
    eventDetailsLinkText: "Event details",
    resourcesIntro:
      "Welcome to the resources section. Below, you will find downloadable documents including teaching guides, presentations, and curriculum documents.",
    availableDocuments: "Available Resources",
    membershipParagraph:
      "Join a community of passionate educators dedicated to promoting French language and culture in Kenya. Discover the benefits of being a KATF member and take the next step in advancing your professional journey.",
    membershipBenefitsHeading: "KATF member benefits",
    memberBenefit1:
      "Free or subsidized studies, seminers/workshops, webinars, conférences",
    memberBenefit2:
      "Free entry to Francophone events organized by katf.eg.French  days, French révision days",
    memberBenefit3: "Eligiblity to vie and vote during KATF elections",
    memberBenefit4: "Participate in competitions and win prizes",
    memberBenefit5: "Welfare and support when a member is bereaved",
    memberBenefit6:
      "Added advantage for success during interviews/career advancement",
    memberBenefit7: "Share happy moments with colleagues",
    memberBenefit8:
      "Better platform to articulate matters affecting teachers of French",
    memberBenefit9:
      "Timely access to information regarding French in Kenya and employment opportunities",
    memberBenefit10:
      "Networking with other partners such as the International Fédération of Teachers of French(FIFP)",
    membershipHowToHeading: "How to become a member ?",
    memberHowStep1: "Be a french teacher",
    memberHowStep2: "Contact your regional treasurer or any current official",
    memberHowStep3: "Pay registration fee",
    paymentGuideHeading: "Membership fees",
    newMemberPaymentH1: "New member",
    newMemberPaymentH2: "Application fee",
    newMemberPaymentH3: "Registration fee",
    contMemberPaymentH1: "Continuing members",
    contMemberPaymentH2: "Annual subscription fee",
    membershipCallHeading: "About membership: From the national treasurer",
    newsPressRelease: "Press Release",
    newsRandomArticles: "Random Articles",
    articleOneDate: "9th September 2024",
    articleTwoDate: "21st August 2024",
    articleThreeDate: "23rd August 2024",
    articleFourDate: "15th July 2024",
    articleFiveDate: "10th September 2024",
    articleSixDate: "1st June 2024",
    articleOneTitle:
      "Partnerships and Collaborations to Enhance the Teaching and Integration of French",
    articleTwoTitle:
      "Mr. Bernard Clouteau was present at the opening of Resource Centre",
    articleThreeTitle: "KATF Web Conference Wrap-Up",
    articleFourTitle: "KATF Elections Announcement",
    articleFiveTitle: "French Language Gains Popularity Among Kenyan Youth",
    articleSixTitle:
      "French Teachers' Training Program Expands to New Counties",
    articleSevenTitle:
      "Mark Your Calendars! The International French Teachers Day (JIPF) Is Almost Here!",
    articleOneContent:
      "The KICD CEO/Director, Prof. Charles Ondo, together with the Senior Deputy Director of Curriculum Development, Madam Jacqueline Onyango, welcomed a delegation from the French Embassy on 9th September 2024. The delegation included Benard Clouteau and Agathe Hume. The meeting focused on discussing partnerships and collaborations to enhance the teaching-learning of the French language in schools.",
    articleTwoContent:
      "Mr. Bernard Clouteau was present at the grand opening of the new Resource Centre at PC Kinyanjui Technical Training Institute (TTI), marking a significant milestone in the institution’s efforts to enhance educational resources. The event not only celebrated the launch of the facility but also underscored the critical role that resource centers play in strengthening French language teaching within Technical and Vocational Education and Training (TVET) institutions.",
    articleThreeContent:
      "The first KATF web conference concluded with a powerful message about the future of the French language in Kenya. The closing remarks emphasized the crucial role that French plays in sustaining the ecosystem of all the Francophonie in the country and urged participants to attach greater value to the language in their daily lives. Attendees were encouraged to reflect on their individual contributions, whether in the classroom, in professional fields, or within KATF, to ensure a brighter future for French in Kenya. The organizing committee extended heartfelt gratitude to all participants, as well as to the French Embassy in Kenya and Somalia, represented by Mr. Bernard Clouteau, for their unwavering support and partnership.",
    articleFiveContent:
      "Recent studies have highlighted a significant increase in interest among Kenyan youth in learning French, driven in part by the integration of modern digital learning tools into school curricula. This trend reflects a broader recognition of the advantages that multilingualism offers in today’s globalized world, particularly in terms of career opportunities and cultural exchange. The incorporation of e-learning platforms, interactive language apps, and online resources has made French more accessible to students, allowing them to engage with the language in a dynamic and personalized way. This growing enthusiasm is a positive indicator for the future of French language education in Kenya, signaling potential growth in both academic and professional spheres.",
    articleSixContent:
      "The French Teachers' Training Program, designed to equip educators with the necessary skills and competencies to teach French effectively, has recently undergone significant expansion, now reaching many teachers within counties in Kenya. This growth is a response to the increasing demand for qualified French language instructors in both urban and rural regions. By offering comprehensive training that covers modern teaching techniques, digital tools, and curriculum implementation, the program aims to enhance the quality of French teaching-learning in schools and higher learning institutions. ",
    articleLinkText: "Read the article",
    formNameLabel: "Your Name",
    formEmailLabel: "Your Email",
    formPhoneLabel: "Your Phone Number",
    formMessageLabel: "Your Message",
    formButtonText: "Send Message",
    formNamePlaceholder: "enter your name",
    formEmailPlaceholder: "enter your email address",
    formPhonePlaceholder: "enter your number",
    formMessagePlaceholder: "enter your message",
    formNameError: "Please enter a valid name (at least 3 letters)!",
    formEmailError: "Please enter a valid email address!",
    formPhoneError: "Please enter a valid phone number!",
    formMessageError: "Please enter your message!",
    formSuccessMessage:
      "Your message has been sent successfully! We will be in touch shortly",
    galleryCaption1:
      "The opening of the 22nd resource center at TVET in Kinyanjui",
    galleryCaption2: "Revision day at St. Peter's Mumias School",
    galleryCaption3: "Participants during the second test session",
    galleryCaption4: "Mr. Eric Wekesa, reviewing the third test",
    galleryCaption5:
      "French teacher and representative of the KUPPET teachers' union with the Secretary General, Mr. Enock Webi",
    galleryCaption6: "The two teams shared a table during the elections",
    galleryCaption7:
      "Competitors for the post of assistant secretary during the elections",
    galleryCaption8: "The seven members of the team that won the elections",
    galleryCaption9:
      "International French Teachers' Day at the Alliance Française in Mombasa, November 25, 2022.",
    galleryCaption10:
      "Mr. Luke Munyendo with other members during French Teachers Day, 2022.",
    galleryCaption11:
      "Mr. Luke Munyendo receives a Jury Certificate thanks to DELF students from Kwale Girls' High School",
    footerParagraphSt1: "Promoting French language teaching/learning.",
    footerParagraphSt2: "Bringing together French language teachers.",
    footerParagraphSt3: "Strengthening professional skills.",
    footerQLHeading: "Quick Links",
    footerLinkItem1: "KATF Home",
    footerLinkItem2: "About Us",
    footerLinkItem3: "Membership",
    footerLinkItem4: "Events",
    footerLinkItem5: "Gallery",
    footerLinkItem6: "Resources",
    footerLinkItem7: "News",
    footerConnectHeading: "Let's Connect",
    footerCopyright:
      "2024 Kenya Association of Teachers of French || Powered By Ra'Asis",
    // resources text
    teachingMaterials: "Teaching materials",
    guidelines: "Guidelines",
    publications: "Publications",
    resourceNames: {
      rapportRevision: "Report About Revision Day at St. Peters High School",
      rapportProfesseurs: "French Teachers Report",
      statutEnseignant: "Tertiary Teacher Status",
      bureauxAdministratifs: "KATF Administrative Offices",
      webConfQuestions: "Key Questions from Web Conference (Aug 21-23)",
      webConfConclusion: "A Summary of This Year's KATF Web Conference",
      webConfProgramme: "KATF Web Conference Program (Aug 21-23)",
      histoireElections: "KATF Election History",
      katfNairobi: "KATF Nairobi",
      katfSeptembre: "KATF September 2024 by Bernard Clouteau",
      presentationCRKF: "CRKF Presentation",
      conference2024: "KATF Conference 2024",
      strategicPlan: "KATF Strategic Plan",
    },
    schoolStatsHeading: "French schools statistics",
    totalSchoolsHeading: "Total number of schools",
    listofSchoolsLinkText: "List of schools",
    schoolsByRegionHeading: "Select the region/category you want to view",
    schoolNameHeading: "School Name",
    countyHeading : "County",
    schoolTypeHeading: "School Type",
    schoolTypeOne: "Public secondary",
    schoolTypeTwo: "Private primary",
    schoolTypeThree: "Private secondary",
    schoolTypeFour: "JSS",
    schoolTypeFive: "First cycle of secondary education",
    schoolTypeSix: "International school",
    schoolTypeSeven: "Primary",
    schoolTypeEight: "Secondary",
    schoolTypeNine: "Tertiary / University",
    schoolNoDataText: "No data available for this region/category",
    eventsSeoDescription: "Discover a vibrant calendar of events at KATF, including past and upcoming conferences, workshops, Teachers' Day celebrations, and interactive webinars. Join us to connect, learn, and grow with educators and thought leaders in the French language.",
    newsSeoDescription: "Find all the latest KATF news. From press releases and events to articles on various subjects.",
    contactSeoDescription: "Contact the Kenya Association of Teachers of French (KATF) for inquiries, collaborations, or support. We're here to connect and assist educators and enthusiasts of French language and culture round the clock.",
    gallerySeoDescription: "Browse the KATF Events Gallery to relive inspiring moments from conferences, workshops, Teachers' Day celebrations, and webinars. See the impact of our events!",
    francophonieSeoDescription: "Francophonie au Kenya brings together all the cooperation activities for French carried out by the cooperation and cultural action service of the French Embassy and its associative and institutional partners.",
    webinarAltText: "The word 'webinar' written on a pink surface with a white mouse, keyboard and a flower pot visible around it.",
    openingResourceAltText: "Guests sitting the main podium at Kinyanjui TTI during the opening of the twenty second resource center in Kenya.",
    onlineTrainingAltText: "A middle aged man sitting on a chair with a laptop and desktop screen on a desk in front of him showing an ongoing online training session with several attendees.",
    defikProjectAltText: "A set of headphones connected to a mobile device resting on a green surface. The words 'Internet', 'Business', 'Education', 'E-Learning', 'Online Training', 'Seminar' and 'Technology' can be seen floating on the image with their shadows.",
    membershipIntroAltText: "A group of corporately dressed people of different racial origins, probably members of a team, stack there hands together on a wooden table.",
    eventCardAltText: " event card",
    publicationOneAltText: "The words 'Parlez-vous Francais?' painted in white on a green background.",
    publicationTwoAltText: "French teacher and representative of the KUPPET teachers' union (left) with General Secretary Mr. Enock Webi (middle) (Katf, 2024)",
    publicationThreeAltText: "A silhouette of people standing on the letters of an extraordinarily large word ELECTION.",
    publicationFourAltText: "The word 'Methodology' written on a wooden table with an old hand clock visible.",
    visionAltText: "An eye icon illustrating and emphasizing the section's heading, 'vision'",
    missionAltText: "An icon showing an arrow hitting its target, illustrating the section's heading, 'mission'",
    valuesAltText: "An icon showing a hand securing a valuable gem, illustrating the section's heading, 'values'",
    aboutMembershipAltText: "A poster showing a message from the National Treasurer of KATF about how to become a member and the benefits.",
    contactAltText: "A young black man smiling and pointing to the position of the contact form on the site page. The man is illustrated mouthing the word 'Bonjour'",
    schoolsByRegionSEOTitle: "Schools By Region",
    schoolsByRegionSEODescription: "Explore schools that teach French as a language by region or category with our easy-to-use directory. Find detailed information about French schools across Kenya, tailored to your location and preferences.",
  },
  fr: {
    home: "Accueil",
    about: "À Propos",
    culture: "Notre Culture",
    team: "Équipe",
    blog: "Blogue",
    events: "Événements",
    upcomingEvents: "Événements à Venir",
    pastEvents: "Événements Passés",
    resources: "Ressources",
    membership: "Adhésion",
    news: "Actualités",
    contact: "Coordonnées",
    gallery: "Photos",
    faqs: "FAQs",
    constructionNotice: "Cette Page Est en Construction",
    introductionText:
      "Bienvenue à l'Association kenyane pour les professeurs de français, KATF! Cette association est dédiée à la promotion de l’enseignement-apprentissage du français au Kenya. Que vous soyez enseignant.e , étudiant.e, ou passionné.e de la langue française, la plateforme de la KATF est votre ressource idéale pour découvrir, apprendre, et partager. Rejoignez-nous pour explorer des ressources pédagogiques de qualité, des événements captivants, et des opportunités de développement professionnel.",
    contactButton: "Pour nous joindre",
    aboutButton: "En savoir plus",
    altText:
      "Une illustration de l'enseignement dynamique du français par membres de l'Association des Professeurs de Français du Kenya",
    rotatingHeaderText: [
      "Éducation de qualité",
      "Ressources innovantes",
      "Communauté engagée",
    ],
    introImageAltTexts: [
      "Les sept membres de l'équipe qui a remporté les élections, le conseil d'administration exécutif",
      "Les participants de St. Peters Mumias Boys lors de la deuxième séance par l'une des intervenantes, Mme Fiona Ketoyo (Katf, 2024).",
      "Inauguration du centre de ressources du PC Kinyanjui TVET par Chargée de Mission de Coopération Linguistique",
      "Professeure de français et représentante de l’union des professeurs KUPPET (à gauche) avec le Secrétaire général M. Enock Webi (au milieu) (Katf, 2024)",
    ],
    aboutBriefHeading: "Historique",
    aboutBriefParagraph:
      "Fondée en 1961, la KATF (Kenya Association of Teachers of French), est un collectif des professeur.es de FLE à travers les différentes régions du pays. Elle compte plus de 100 membres cotisés chaque année dont 7 représentant.es du bureau exécutif et 7 autres dans les régions.",
    aboutBriefLinkText: "En savoir plus sur la KATF",
    achievementsHeading: "Réussites de la KATF",
    achievementOneHeading: "Webinaires",
    achievementOneDescription:
      "Les webinaires du Réseau Canopé ont offert des points de vue d’experts, des outils pratiques et un apprentissage interactif.",
    achievementTwoHeading: "Inauguration d'un centre de ressources",
    achievementTwoDescription:
      "Inauguration du 22ème centre de ressources au TVET de Kinyanjui.",
    achievementThreeHeading: "Formations",
    achievementThreeDescription:
      "Formation virtuelle à l'Université Kenyatta. Formation en présentiel à l'Université de Nairobi.",
    achievementFourHeading: "Le projet DEFIK",
    achievementFourDescription:
      "Le projet DEFIK a connu un grand succès. Un avenir prometteur.",
    achievementExploreLinkText: "En savoir plus",
    membershipIntroParagraph:
      "En s’adhérant à l'Association kényane des professeurs de français au Kenya (KATF), vous ferez partie d’une communauté dynamique d'éducateurs passionnés par l’enseignement-apprentissage de la langue française au Kenya. En tant que membre, vous avez accès à une plateforme ayant une grande variété de ressources exclusives et vous bénéficiez de partages et échanges avec d'autres enseignants. Votre  développement professionnel est aussi assuré grâce aux formations offertes.",
    membershipInfoLinkText: "Adhésion à la KATF",
    membershipApplyLinkText: "Devenir membre",
    partnerRotatingHeadings: [
      "Collaborer pour avoir des impacts positifs ",
      "Créer des réseautages au niveau global",
      "Renforcer les réseaux éducatifs en français",
    ],
    partnerIntroParagraph:
      "Nos partenaires jouent un rôle important dans l’avancement de la mission de l’Association kenyane des professeurs de français. Grâce à des collaborations stratégiques et à des ressources partagées, nous sommes en mesure d’améliorer l’enseignement et l’apprentissage du français dans toute la région. Ensemble, nous favorisons l’interculturalité, assurons l’excellence dans l’éducation et créons des opportunités pour les étudiants et enseignants. Nous sommes fiers de travailler en partenariat avec des institutions, organisations et  communautés,  partageant notre vision d’un avenir meilleur et plus connecté.",
    francophonieLinkText: "Découvrez la Francophonie au Kenya",
    francophonieParagraph:
      "La Francophonie au Kenya regroupe l'ensemble d’activités de coopération menées par le Service de coopération et d'action culturelle de l'Ambassade de France et ses partenaires associatifs et institutionnels. Rejoignez dès aujourd'hui le nouveau forum WhatsApp de La Francophonie au Kenya, pour avoir accès à toutes nos actualités et infos sur des webinaires, événements, formations, concours,  etc. ",
    francophonieWhatsappLinkText: "Rejoignez la communauté WhatsApp",
    francophonieHeading1: "Étudier et travailler en France",
    francophonieHeading2: "Réseaux importants pour les professeurs de français",
    francophonieHeading3: "Projets francophones",
    mainEventDetails: "Le 29 Novembre 2024 | L'Alliance Française de Mombasa, KE",
    mainEventTitle: "La Journée Internationale des Profs de Français (JIPF)",
    mainEventBenefit:
      "Toutes championnes, tous champions, porteurs de la flamme francophone",
    mainEventParagraph:
      "Tous les professeurs de français ont été célébrés le 23 novembre à l'université de Maseno. Les célébrations se sont poursuivies le 29 novembre à l'Alliance Française de Mombasa. Le Jour des profs de français rassemble les enseignants de français et aussi ceux qui enseignent en français dans les formations bilingues, partout dans le monde.",
    eventLinksText: "En savoir plus",
    eventTwoDetails:
      "27 Février 2025 | Alliance Française, rue Monrovia, Nairobi, KE",
    eventTwoTitle: "Atelier sur la pédagogie",
    eventTwoBenefit: "Améliorez vos stratégies d'enseignement",
    eventTwoParagraph:
      "Découvrez les techniques et méthodologies innovantes dans l’enseignement-apprentissage du français lors des ateliers pédagogiques.",
    eventThreeDetails:
      "17 Mars 2025 | Hôtel Safari Park, chemin Thika, Nairobi, KE",
    eventThreeTitle: "Séminaire d'immersion culturelle",
    eventThreeBenefit: "Renforcez vos compétences culturelles",
    eventThreeParagraph:
      "Vivez une expérience d'immersion culturelle enrichissante. Explorez le patrimoine et les traditions des pays francophones.",
    eventFourDetails:
      "23 Juillet 2025 | Centre International de Congrès Kenyatta, Harambee Ave, Nairobi, KE",
    eventFourTitle: "Outils numériques",
    eventFourBenefit: "Intégrez la technologie dans vos cours de français",
    eventFourParagraph:
      "Un atelier pratique axé sur l'utilisation des outils numériques dans l'enseignement-apprentissage du français",
    publicationsHeading: "Nos publications",
    publicationOneTitle:
      "Rapport sur l’enseignement du français au Kenya : Historique, état des lieux et perspectives",
    publicationOneExcerpt:
      "Le rôle essentiel du français dans  les échanges linguistiques, culturels et économiques au Kenya....",
    publicationTwoTitle:
      "Rapport du Secrétaire général sur la journée de révision au lycée St. Peters",
    publicationTwoExcerpt:
      "L’Association a organisé une journée de révision qui a eu lieu le 21 juillet, 2024 au lycée St. Peter’s...",
    publicationThreeTitle:
      "SOUFFLE Par Ashioya Boniface : Le vent des élections souffle à travers le pays",
    publicationThreeExcerpt:
      "Les élections arrivent, les élections passent, les années passent, et le vent des élections dans le cadre de la KATF souffle...",
    publicationFourTitle:
      "Enseignement-apprentissage de l’écrit en français au Kenya",
    publicationFourExcerpt:
      "Adopter des méthodologies efficaces dans l'enseignement du français est un grand défi et nécessite des compétences chez les enseignants...",
    callToAction: "Améliorez votre parcours enseignant ",
    callToActionLinkText: "Faîtes partie de la communauté KATF",
    aboutHeadingText: [
      "Renforcer l’enseignement-apprentissage du français au Kenya",
      "Créer des passerelles à travers la langue et la culture",
      "À l’avant-garde d’un avenir bilingue",
    ],
    aboutUsHeading: "À propos de la KATF",
    aboutUsParagraph:
      "L'Association kenyane pour les professeurs de français (KATF) a été fondée en 1961 par des professeurs de français des écoles de l'église catholique dont : Loreto, Mang'u High School et St. Mary's School. Peu de temps après, d'autres écoles, fondées et gérées par des missionnaires, les ont rejoints : les écoles Duke of York (Lenana School) et Prince of Wales (Nairobi School) parmi d’autres. La KATF a été officiellement enregistrée auprès de l’Office de l’état civil le 21 juin 1968 ; son certificat d'enregistrement numéroté R. 4711. Actuellement, l’Association est un collectif des professeurs de français exerçant dans les établissements et institutions reparties dans toutes les régions du pays. Elle compte plus de 100 membres cotisés chaque année, dont 7 représentant.es du bureau exécutif et 7 autres dans les régions. Le développement des relations diplomatiques et commerciales avec certains pays africains francophones justifie l'intérêt de l'enseignement du français. En 1971, la France et le Kenya signent un accord de coopération culturelle. L'accord prévoyait la mise en place d'échanges de chercheurs, d'étudiants et d'enseignants entre les deux pays.",
    aboutUsMoreText: "Lire la suite",
    aboutUsLessText: "Afficher moins",
    missionHeading: "Notre mission",
    visionHeading: "Notre vision",
    valuesHeading: "Nos valeurs",
    missionStatement:
      "Renforcer les capacités des enseignants de français au Kenya, pour qu'ils puissent apporter des  impacts positifs et influences dans le monde éducatif.",
    visionStatement:
      "Un enseignant de français compétent, bien motivé et compétitif à l'échelle internationale.",
    valuePoints:
      "Information. Professionalisme. Inclusivité. Respect. Esprit d'equipe. Transparence.",
    valueOne: "Information",
    valueTwo: "Professionalisme",
    valueThree: "Inclusivité",
    valueFour: "Respect",
    valueFive: "Esprit d'équipe",
    valueSix: "Transparence",
    objectivesHeading: "Nos objectifs",
    objectives: [
      "Promouvoir l’enseignement/l’apprentissage du français au Kenya à tous les niveaux du système éducatif kenyan.",
      "Faire valoir l’enseignement / apprentissage de la langue française au Kenya.",
      "Œuvrer pour l’élargissement et l’amélioration de la qualité de la langue française au Kenya.",
      "Dynamiser le processus de modernisation des stratégies d’enseignement/apprentissage du français.",
      "Éveiller l’intérêt des enseignants à la recherche scientifique, à l’innovation didactique.",
      "Faciliter les débats professionnels des enseignants de français au Kenya ; Contribuer à la stimulation du dialogue professionnel et de la réflexion pédagogique.",
      "Proposer des forums d’échanges d’idées entre les spécialistes kenyans et étrangers dans le domaine de la didactique de la langue française, en vue de faire connaître à la communauté des professeurs au Kenya, les derniers progrès de la théorie et de la pratique dans ce domaine au développement dynamique.",
      "Susciter la mise en commun des ressources, des expériences et des recherches pédagogiques pour un enrichissement réciproque.",
      "Défendre devant des organes et institutions compétents l’application d’une politique équilibrée d’apprentissage des langues en conformité avec la spécialité nationale, les tendances régionales et mondiales et qui puissent garantir une part adéquate dans la politique globale de l’enseignement de la langue française au Kenya.",
      "Consolider l'intérêt et la motivation des apprenants dans la perspective d'une meilleure compréhension des éléments civilisationnels spécifiques à l'espace francophone.",
      "Favoriser le dialogue interculturel et les échanges humains dans le contexte francophone.",
      "Promouvoir, soutenir et maintenir de bonnes relations avec le Ministère de l'éducation, les directeurs/directrices des écoles où le français est enseigné, les universités et collèges, l'Alliance Française, l'Ambassade de France, l’APFA-OI; la Fédération Internationale des Professeurs de français (FIPF), le Comité Permanent pour la Francophonie et d'autres organisations qui s'intéressent aux activités de l’Association.",
      "Veiller à la reconnaissance institutionnelle des qualifications des professeurs dans les domaines de FLE ainsi que dans ceux des organismes qui apprécient le français comme langue de travail.",
      "Travailler en coopération et étroitement avec d’autres organismes de la francophonie, professionnels, éducatifs ou francophile dans la promotion de la langue française voire le Ministère de l’Education, l’Ambassade de France, l’Alliance Française, l’Association des Professeurs de Français en Afrique et l’Océan Indien (APFA-OI), la Fédération Internationale des Professeurs de Français (FIPF), le Comité Permanent pour la Francophonie (CPF) et des écoles où le français est enseigné.",
    ],
    presidentPosition: "Présidente",
    presidentDescription:
      "Dr. Leonorah Lutiali Mugalla est titulaire de doctorat, obtenu de l'Université Kenyatta. 26 ans d'expérience professionnelle dans l'enseignement de la langue française, enseignante expérimentée qui exerce actuellement à l’université (CUEA). Elle donne cours de français dans d’autres universités et a encadre Auteure de la méthode « Au Sommet », une série de 4 livres. Elle a également contribué au développement des programmes de français dans le cadre du programme d’éducation basé sur les compétences (CBC). et dirigé des initiatives en matière de langue française, notamment l'organisation de la Journée de la Francophonie. Elle est chargée de cours dans diverses universités et a été examinatrice pour le DELF et le KCSE. De plus, elle est active dans le mentorat communautaire et le leadership éducatif",
    deputyPresidentPosition: "Vice-Président ",
    deputyPresidentDescription:
      "M. Luke Munyendo est titulaire de Licence en éducation. Membre dévoué de la KATF. Examinateur (KCSE-KNEC) ; Formateur et membre de jury (Kenya Music Festival). En tant que président de la KATF pour la région côtière, il a établi de solides collaborations avec l’Alliance française de Mombasa, l’Ambassade de France et le Ministère de l’Éducation. M. Munyendo s’engage à renforcer des partenariats et programmes d’échange pour l’enseignement -apprentissage efficace de français.",
    secGenPosition: "Secrétaire Général",
    secGenDescription:
      "M. Enock Webi est titulaire d’une maîtrise et poursuit son doctorat à MMUST. Expérience en diplomatie et en relations internationales M. Webi a contribué de manière significative à la l’enseignement-apprentissage de la langue française, en défendant les centres de ressources, en organisant des ateliers et événements en français. De plus, il est auteur et créateur de contenus pour les cours de français en ligne (Plateforme Zeraki).",
    assSecGenPosition: "Secrétaire Général Adjoint",
    assSecGenDescription:
      "Madame Eshery Munala est enseignante de la langue française ayant une expérience professionnelle de 26 ans. Doctorante (Educational Administration). Membre de la KATF depuis 2000. Formatrice de formateurs dans le cadre du CFTD. Elle est actuellement directrice de l'école secondaire C.G.H.U à Nairobi et a précédemment occupé des postes de direction dans des écoles de Kajiado et Narok. Formatrice et membre du jury (Festivals de théâtre et musique). Auteur d'un livre en français pour la révision.",
    treasurerPosition: "Trésorier",
    treasurerDescription:
      "M. Timothy Muthoka Kyeva est membre actif de la KATF. Examinateur-correcteur KCSE et DELF-DALF). A joué un rôle important dans l'enseignement de la langue française, reconnu « le Meilleur enseignant de l'année (TOYA) à Nairobi en 2019. En tant que trésorier, il œuvre avec le bureau national, pour obtenir des ressources financières et matérielles, afin de mettre en œuvre les projets de la KATF et ainsi faciliter la réalisation de sa mission et vision.",
    flottant1Description:
      "Dr. Nuru Kyalo est titulaire d'un doctorat en sciences du langage (sémiotique) de l'Université de Limoges et de l’Université de Maseno. Titulaire d'une licence en éducation et d'une maîtrise ès arts de l'Université Kenyatta. Elle travaille à l'Université de Maseno depuis 2008. Dr. Kyalo est également présidente et directrice de l'Association des écrivains francophones kenyans (AFKW). Elle encadre des écrivains francophones kenyans. En outre, elle est directrice de La Collection poésie francophone kényane, une anthologie annuelle de poésie kenyane",
    flottant2Description:
      "M. Robert Matunyo enseigne le FLE, depuis plus de cinq ans. Examinateur (KCSE- au KNEC). M. Matunyo représente des jeunes enseignants et souhaite travailler en étroite collaboration avec le bureau national pour unir et apporter le soutenir aux « groupes spéciaux », contribuant ainsi à la réalisation de la mission et vision de l'association.",
    executiveHeading: "Le conseil d’administration",
    executiveParagraph:
      "Rencontrez les dirigeants dévoués de l'Association kenyane des professeurs de français, engagés à faire progresser l’enseignement-apprentissage de la langue française et promouvoir les objectifs de l'Association.",
    structureHeading: "La structure de la KATF",
    structureParagraph:
      "L'Assemblée générale au sommet, est l’organe collectif et suprême de l’Association. Elle est composée de tous les membres actifs et adherents de l’Association. Au-dessous se trouve Le Conseil D’administration, qui se compose du président,  vice-président,  secrétaire général,  secrétaire général adjoint, trésorier et de deux Membres Flottants. Sous le Conseil D’administration se trouve Les Organes Directeurs Régionaux, chargé des opérations de l'Association. Au niveau régional, chaque organe régional se compose d'un président,  secrétaire et un trésorier, assurant la mise en œuvre des initiatives de la KATF. Il y a huit régions : Nairobi, Nyanza, La Région Centrale, La Région de l'Est, La Côte, La Région de l'Ouest, Le Nord de la Vallée de Rift et Le Sud de la Vallée de Rift.",
    regionalGovernersHeading: "Les organes directeurs régionaux",
    regionNameOne: "Région de Nairobi",
    regionNameTwo: "Région de l'est",
    regionNameThree: "Région de l'Ouest",
    regionNameFour: "Région centrale",
    regionNameFive: "Région de Nyanza",
    regionNameSix: "Région du nord de la Vallée de Rift",
    regionNameSeven: "Région du sud de la Vallée de Rift",
    regionNameEight: "Région de la côte",
    presidentMalePosition: "Président",
    secretaryPosition: "Secrétaire",
    treasurerFemalePosition: "Trésorière",
    flotantHeading: "Membres Flottants",
    teacherNumbersHeading: "Voici nos chiffres actuels",
    teachersTableHeading1: "No.",
    teachersTableHeading2: "Établissement",
    teachersTableHeading3: "Nombre d’enseignants",
    teacherNumbersParagraph:
      "Découvrez la communauté dédiée et réseau de professeurs de français repartis dans le pays, unis par leur passion pour l'enseignement-apprentissage de la langue française. Voici les effectifs actuels...",
    establishment1: "Primaires / Privée",
    establishment2: "Secondaires",
    establishment3: "Instituts de formation techniques (TVET)",
    establishment4: "Écoles Polytechniques coles Nationales",
    establishment5: "Écoles normales (TTC)",
    establishment6: "Universités",
    aboutOutroHeading:
      "Rejoignez-nous pour façonner l'avenir de l’enseignement-apprentissage de français",
    aboutOutroLinkText: "Devenir membre",
    conferenceEventCategory: "Conférences",
    webinarEventCategory: "Webinaires",
    workshopEventCategory: "Ateliers",
    frenchDaysEventCategory: "Journées Françaises",
    otherEventCategory: "Autres Événements",
    webConferenceDateRange: "Du 21 au 23 août 2024",
    webConferenceTitle: "Webconférence KATF",
    webConferenceDescription: {
      title: "Questions Clé de la Web-Conférence",
      sections: [
        {
          heading: "Premier Jour",
          questions: [
            "La KATF fête ses 63 ans cette année, en tant qu’individu, qu’avez-vous fait pour votre association pendant ces années ?",
            "Le français n’est plus la langue de Molière, c’est une langue à vous, quelle valeur attachez-vous à votre langue ?",
          ],
        },
        {
          heading: "Deuxième Jour",
          questions: [
            "La francophonie au Kenya rassemble des mouvements diversifiés, quel est votre engagement personnel en tant que militant du progrès de la langue française au Kenya au sein et/ou en dehors de l’écosystème scolaire ?",
            "L’ère numérique est avec nous et implante vite ses racines dans l’enseignement de la langue française. Que feriez-vous afin de rester pertinent à l’avenir ?",
            "Y a-t-il un avenir pour la littérature francophone kényane ?",
            "Comment peut-on fédérer et valoriser toutes les actions des mouvements francophones au Kenya ?",
            "Profitez-vous pleinement des nombreuses actions et projets mis en place par notre partenaire l’Ambassade de France au Kenya ?",
            "Le CBC est une approche basée sur les compétences et demande la créativité de la part de l’enseignant. Sommes-nous prêts à enseigner le CBC ?",
            "Du 8-4-4 vers CBC, y aurait-il des défis dans l’enseignement du français occasionnés par le changement du système éducatif ? Comment allons-nous affronter ces défis ?",
          ],
        },
        {
          heading: "Troisième Jour",
          questions: [
            "Il existe une mine de ressources sur le CRKF en ligne. Avez-vous déjà accès aux ressources sur CRKF ?",
            "L’espace (physique / virtuel) de classe évolue, et vous ?",
            "Quelle place pour le poème et la pièce de théâtre dans une salle de classe ?",
            "Comment harmoniser l’expérience en classe de FLE ?",
            "Vous êtes enseignant de FLE et formez de grandes personnalités dans le monde. Vous prenez soin des enfants d’autrui pendant des années. Qui prend soin de vous ?",
            "Investissez-vous sur vous-même ?",
            "Pour vous, « Avancer » c’est quoi ?",
            "Quelles autres compétences possédez-vous ? Comment les valoriser ?",
            "Quelles sont les autres carrières qui vous fascinent ?",
            "Êtes-vous visible au monde ?",
            "Quels sont les besoins des établissements TVETs et « colleges » pour le progrès du français à ces niveaux ?",
            "Les professeurs universitaires sont-ils prêts à recevoir les apprenants du nouveau système éducatif en 2029 ?",
            "Les étudiants de CBC auront-ils le bon niveau d’entrée pour poursuivre les cours en français à l’université ?",
            "La formation des professeurs sera-t-elle suffisante au niveau de TTC et universitaire ?",
            "Quelles interventions mettre en place pour se préparer pour l’avenir du français à l’université ?",
          ],
        },
      ],
      conclusion: [
        "En tant que membre de la KATF, vos réponses à toutes ces questions déterminent l’avenir de la langue française.",
        "C’est la langue française qui fait vivre l’écosystème de la francophonie au Kenya. Elle est la source de notre gagne-pain. Il faut attacher une valeur plus profonde à la langue française pour qu’on puisse militer pour son progrès et son meilleur avenir.",
        "Décidez-vous dès aujourd’hui ce que vous allez faire pour la langue française ; soit en classe soit en dehors de la classe, pour la KATF, ailleurs dans les différents domaines professionnels, et même pour vous-même pour assurer son avenir au Kenya.",
        "La conférence Web de la KATF s’est conclue avec succès, les participants ayant bénéficié d’échanges fructueux. Par la suite, le KATF a organisé des réunions consultatives avec les enseignants du primaire et du secondaire, les éducateurs des établissements d’enseignement supérieur – TTC et TVET – et ceux du niveau universitaire. Ces réunions visaient à mieux comprendre les besoins spécifiques de chaque groupe d’éducateurs. Le KATF s’est ensuite engagé à répondre aux besoins uniques des enseignants à tous les niveaux d’enseignement. Le Dr Nuru Kyalo, aux côtés de la KATF, des mouvements de la Francophonie au Kenya et de l’ambassade de France, ont exprimé leur dévouement à cette mission, encourageant d’autres à se joindre à leurs efforts. Un appel a été lancé pour changer de perspective, exhortant les éducateurs et les parties prenantes à se demander : « Que puis-je faire pour le KATF ? » au lieu de « Que fait le KATF pour moi ? »",
        "Au nom du comité d'organisation, des bureaux régionaux de la KATF et du conseil exécutif de la KATF, le Dr Nuru Koki Kyalo a exprimé sa sincère gratitude à tous les participants pour leur implication active et leurs précieuses contributions tout au long de la conférence.",
        "Un merci tout particulier a également été adressé à l'ambassade de France au Kenya et en Somalie, représentée par M. Bernard Clouteau, pour son soutien indéfectible. L'ambassade a été reconnue comme un partenaire indispensable dans la mission de la KATF visant à faire progresser la langue française vers un avenir meilleur, et ses efforts ont été profondément appréciés.",
        "La conférence Web de la KATF s'est conclue par une prière de clôture sincère, mettant ainsi un terme significatif à l'événement.",
      ],
    },
    languageWorkshopDateRange: "Avril 2024, 5 Jours",
    languageWorkshopTitle: "Atelier de Conception de 15 Modules Linguistiques",
    languageWorkshopLocation: "Naivasha, Kenya.",
    languageWorkshopDescription: `Résumé
      Un atelier de production de 15 modules d’une heure chacun sur renforcement linguistique chez les enseignants. Il y a eu la création de ressources numériques en FLE 
      Dates : avril 2024 
      Durée :5 jours
      Lieu : Naivasha, Kenya
      Nombre de participants : 17
      Intervenant/animateur : Médiateur de Réseau Canopé
      `,
    fosWorkshopDateRange: "Avril 2024, 5 Jours",
    fosWorkshopTitle: "Formation à la Scénarisation Pédagogique en FOS",
    fosWorkshopLocation: "Naivasha, Kenya.",
    fosWorkshopDescription: `Résumé
      Une formation sur la production de ressources numériques dans le cadre du Français sur Objectifs Spécifiques. Cette formation a été conçue pour renforcer les compétences des participants dans la création et l'utilisation d'outils numériques adaptés à l'enseignement du français dans des contextes spécifiques.
      Dates : avril 2024 
      Durée : 5 jours
      Lieu : Naivasha, Kenya
      Nombre de participants : 18
      Intervenant/animateur : médiateur de Réseau Canopé
      `,
    electionsDateRange: "Samedi Le 27 Avril",
    electionsTitle: "Élections de la KATF",
    electionsLocation: "Nakuru High School, Nakuru.",
    electionsDescription: `Cette année, l’association s’est préparée à réaliser un évènement remarquable- une assemblée générale exceptionnelle lors de laquelle les membres allaient voter des nouveaux candidats afin de mettre en place un nouveau bureau au service de ses membres.
      APPELS À CANDIDATURES Tous les postes ayant été déclarés vacants, un appel à candidatures a été fait le 1er avril aux candidat.es intéressé.es. Les personnes mentionnées devraient respecter les directives prévues par le comité électoral de l’association dont : ✔l’accessibilité à la constitution ne serait accordée qu’aux membres actifs.
      ✔la date limite du dépôt et de la réception des candidatures se déroulerait entre le 1er et le 6 avril.
      ✔toute communication officielle de l'association serait faite par le biais des responsables élus de la KATF (bureaux nationaux et régionaux) DES CAMPAGNES EXTRAORDINAIRES Suite aux plaintes associées à la disponibilité de la constitution et du statut de membre par certain.es candidat.es réuni avec d'autres membres, des débats intenses à propos des organisateurs y compris les dirigeants des élections et l'indifférence des uns contre des autres selon leurs points de vue envers le processus même, le premier candidat a finalement lancé sa campagne et celle de son équipe. M Jean BARON- l'aspirant trésorier issu de la 1ère équipe sous le slogan << AUTRES TEMPS, AUTRES RHYTHMES>>, a ouvert le bal de ses campagnes ! La leadeuse de son équipe était Mme Kate SAKARI- l’aspirante présidente- un poste pour lequel elle était tout aussi qualifiée que son adversaire de la 2e équipe, Dr Léonorah LUTIALI.
      Du coup, ne voulant se laisser distancer, la 2e équipe, sous le slogan << L'ÉQUIPE POUR UN AVENIR BRILLANT>>, a suivi le mouvement. M Enock WEBI- son aspirant secrétaire général, a dû présenter tous les 7 membres de l’équipe aux électeurs potentiels.
      Il s’agissait d’une course marathon entre les deux camps forts, chacun défiant son adversaire avec des plans stratégiques, des visions et des programmes bien étudiés. Les rumeurs et la propagande jouaient parfois à l’avantage ou au désavantage des adversaires, une stratégie qui n’était pas de bon augure pour la plupart des membres. Mais en dépit de ce que certains considèrent comme de la bonne ou de la mauvaise politique, les partisans.es des candidats, ne laissant rien au hasard, ont chacun fait campagne pour leur prétendant.e préféré.e à chaque seconde… minute … instant… Et comme le diraient certains membres du collectif, ça a énormément chauffé !!!
      LE JOUR OÙ… Nakuru High School, samedi le 27 avril dès 09h00, legrand jour est enfin arrivé- une assemblée générale trop attendue ! Des professeur.es sont venus.es en grand nombre afin d’assister à cet évènement exceptionnel. Une journée remplie d’activités nombreuses ainsi qu’importantes, le point culminant était l’élection des nouveaux membres du bureau exécutif. Une fois la réalisation des diverses activités accomplies, il était temps de voter. Après quelques soixantaines de minutes, tous les électeurs inscrits avaient voté et aux alentours de 18h00, les résultats étaient finalement annoncés… Par coïncidence ou non, les 7 membres du 2e camp, dirigés par Dr. Léonorah LUTIALI, ont tous été élus ! C'est eux qui allaient désormais siéger au bureau exécutif voire national jusq'au bout de 2 ans.
      C’était magnifique de voir que malgré leurs divergences d’opinion au cours des campagnes, ce fut un moment d’unité couplé avec des célébrations pour tout le monde, les deux équipes y comprennent- gagnante et perdante. Nous avions tous gagné. La KATF a gagné.
      <<VIVE LA KATF ET FÉLICITATIONS À L'ÉQUIPE POUR UN AVENIR BRILLANT!>>`,
    openingResourceDateRange: "Août 2024",
    openingResourceTitle:
      "Inauguration du 22ème Centre de Ressources au TVET de Kinyanjui",
    openingResourceLocation: "À Kinyanjui TVET",
    openingResourceDescription:
      "Inauguration du 22e Centre de ressources TVET de Kinyanjui marque une étape importante dans l’avancement de l’apprentissage et de l’enseignement du français au Kenya. Ce nouveau centre jouera un rôle essentiel dans la fourniture de ressources indispensables aux enseignants et aux étudiants des établissements d’enseignement et de formation techniques et professionnels (EFTP). En proposant des outils innovants et un accès à des supports en langue française, le centre vise à améliorer les compétences des apprenants, les préparant à de futurs projets académiques et professionnels dans des contextes francophones. Cette initiative soutient également la croissance du français en tant que langue clé dans le système éducatif kenyan en pleine évolution, favorisant les échanges culturels et créant de nouvelles opportunités pour les étudiants et les enseignants.",
    frenchTeachersDayDateRange: "Le 23 et 29 Novembre 2024",
    frenchTeachersDayLocation: "Université Maseno, Maseno, KE et L'Alliance Française de Mombasa, KE",
    frenchTeachersDayDescription:
      "Tous les enseignants de français ont été célébrés le 23 novembre à l'Université de Maseno et le 29 novembre à l'Alliance française de Mombasa !!!!! Le thème de cette année était : « Tous champions, porteurs de la flamme francophone ». La célébration de cette année était plus grande et plus intéressante ! Des activités intéressantes ont été organisées pour les professeurs de français au Kenya ! Le nombre de places disponibles pour une participation en personne à Mombasa était limité. Avez-vous de bonnes idées pour rendre la fête plus agréable l'année prochaine ? Envoyez vos idées à la KATF par l'intermédiaire du Secrétaire général.",
    frenchTeachersDayLinkText: "En savoir plus sur JIPF",
    pedagogyWorkshopDateRange: "27 Février 2025",
    pedagogyWorkshopTitle: "Atelier sur La Pédagogie",
    pedagogyWorkshopLocation: "Alliance Française, rue Monrovia, Nairobi, KE",
    pedagogyWorkshopDescription:
      "Découvrez des techniques et des méthodologies d'enseignement innovantes lors de l'atelier de pédagogie du français, conçu pour doter les enseignants d'outils modernes pour un enseignement efficace de la langue. Cet atelier se penchera sur les dernières tendances pédagogiques, en explorant des approches interactives qui engagent les élèves dans des expériences d'apprentissage dynamiques et immersives. Les participants auront l'occasion de collaborer avec d'autres enseignants, de partager les meilleures pratiques et d'acquérir une expérience pratique des nouvelles technologies d'enseignement. Que vous cherchiez à améliorer vos stratégies de classe ou à vous tenir au courant de l'évolution des normes d'éducation, cet atelier offre des informations et des ressources précieuses pour vous aider à exceller dans l'enseignement du français langue seconde.",
    culturalImmersionDateRange: "17 Mars 2025",
    culturalImmersionTitle: "Webinaire sur L'immersion Culturelle",
    culturalImmersionDescription:
      "Vivez dans une expérience culturelle transformatrice en explorant le patrimoine dynamique et les riches traditions des pays francophones lors de notre atelier d'immersion culturelle. Ce voyage enrichissant offre aux participants une occasion de s'engager dans la musique, l'art, la cuisine et les coutumes qui définissent le monde francophone. Grâce à des sessions interactives, discussions et activités, vous allez apprécier et comprendre des nuances culturelles et les particularités de ses locuteurs. Que vous soyez éducateur, étudiant ou vous êtes passionné de la culture, cette expérience va élargir vos perspectives, favorisera la compréhension aux niveaux interculturel et intra-culturel. De plus, cette immersion fournit des opportunités pour approfondir et élargir votre réseautage professionnel et engagement entre les membres de la communauté francophone. Allez au-delà de la langue et découvrez le cœur et l'âme des cultures francophones!",
    digitalToolsDateRange: "10 Août 2025",
    digitalToolsTitle: "Conférence sur Les Outils Numériques",
    digitalToolsLocation:
      "Centre International de Conférences Kenyatta, Harambee Ave, Nairobi, KE",
    digitalToolsDescription:
      "Rejoignez-nous pour un atelier pertinent et pratique conçu pour explorer les derniers outils numériques et leur rôle dans la transformation de l'enseignement des langues. Au cours de cette session interactive, vous allez apprendre à intégrer des technologies innovantes dans vos pratiques d'enseignement pour stimuler l'engagement des étudiants et améliorer l'apprentissage.",
    eventDetailsLinkText: "Détails...",
    resourcesIntro:
      "Vous trouverez ci-dessous des documents téléchargeables, notamment des guides d'enseignement, des présentations et des documents de programme.",
    availableDocuments: "Ressources Disponibles",
    membershipParagraph:
      "Rejoignez une communauté d'éducateurs passionnés qui se consacrent à la promotion de la langue et de la culture françaises au Kenya. Découvrez les avantages d'être membre de la KATF et franchissez une nouvelle étape dans votre parcours professionnel.",
    membershipBenefitsHeading:
      "Avantages pour les membres adhérents de la KATF",
    memberBenefit1:
      "Études gratuites ou subventionnées, séminaires/ateliers, webinaires, conférences",
    memberBenefit2:
      "Entrée gratuite aux événements francophones organisés par la KATF, par exemple les journées françaises, les journées de révision du français",
    memberBenefit3:
      "Éligibilité à candidater et à voter lors des élections de la KATF",
    memberBenefit4: "Participer à des concours et gagner des prix ou cadeaux",
    memberBenefit5: "Soutien en cas de deuil",
    memberBenefit6: "Un atout pour le développement professionnel",
    memberBenefit7: "Partager des bons moments avec des collègues",
    memberBenefit8:
      "Meilleure plateforme pour articuler les questions qui touchent les professeurs de français",
    memberBenefit9:
      "Accès rapide aux informations concernant le français au Kenya et aux opportunités d'emploi",
    memberBenefit10:
      "Réseautage avec d'autres partenaires tels que la Fédération internationale des professeurs de français (FIFP)",
    membershipHowToHeading: "Comment devenir membre ?",
    memberHowStep1: "Devenir professeur de français",
    memberHowStep2:
      "Contactez votre trésorier régional ou n’importe quel membre du bureau exécutif actuel",
    memberHowStep3: "Payer les frais d'inscription",
    paymentGuideHeading: "Cotisations",
    newMemberPaymentH1: "Nouveaux membres",
    newMemberPaymentH2: "Frais d'application",
    newMemberPaymentH3: "Frais d'inscription",
    contMemberPaymentH1: "Membres déjà inscrits",
    contMemberPaymentH2: "Abonnements annuels",
    membershipCallHeading: "À propos de l'adhésion : du trésorier national",
    newsPressRelease: "Communiqués de Presse",
    newsRandomArticles: "Articles Aléatoires",
    articleOneDate: "Le 9 Septembre 2024",
    articleTwoDate: "Le 21 Août 2024",
    articleThreeDate: "Le 23 Août 2024",
    articleFourDate: "Le 15 Juillet 2024",
    articleFiveDate: "Le 10 Septembre 2024",
    articleSixDate: "Le 1er Juin 2024",
    articleOneTitle:
      "Partenariats et Collaborations pour Améliorer L'enseignement et L'intégration du Français",
    articleTwoTitle:
      "Chargée de Mission de Coopération Linguistique M.Bernard Clouteau Présent lors de L'inauguration du Centre de Ressources",
    articleThreeTitle: "Bilan: Web Conférence de la KATF",
    articleFourTitle: "Annonce des Élections",
    articleFiveTitle:
      "Engouement Pour La Langue Française Chez Les Jeunes Kenyans ",
    articleSixTitle:
      "Programme de Formation des Enseignants de Français a Pris de L’envergure Dans Les  Comtés",
    articleSevenTitle:
      "La Journée Internationale des Professeurs de Français (JIPF) Approche à Grands Pas!",
    articleOneContent:
      "Le Directeur Général du KICD, le Professeur Charles Ondo, accompagné de la Directrice Adjointe Principale du Développement Curriculaire, Madame Jacqueline Onyango, ont accueilli aujourd'hui 9 septembre 2024 une délégation de l'Ambassade de France. La délégation comprenait Benard Clouteau et Agathe Hume. Le Directeur Général du KICD, le Professeur Charles Ondo, accompagné de la Directrice Adjointe Principale du Développement Curriculaire, Madame Jacqueline Onyango, ont accueilli aujourd'hui 9 septembre 2024 une délégation de l'Ambassade de France. La délégation comprenait Benard Clouteau et Agathe Hume. Des discussions à propos de partenariats et collaborations visés à l'enseignement-apprentissage efficace, réussi de la langue française dans les écoles ont été tenues.",
    articleTwoContent:
      "Monsieur Bernard Clouteau était présent lors de l’inauguration du nouveau centre de ressources à PC Kinyanjui Technical Training Institute,  marquant une étape importante dans la mobilisation de ressources éducatives. L’événement a a également souligné le rôle essentiel que jouent les centres de ressources dans le renforcement de l’enseignement du français au sein des établissements d’enseignement et de formation techniques et professionnels (EFTP).",
    articleThreeContent:
      "La première conférence web de la KATF s’est conclue par un message fort sur l’avenir de la langue française au Kenya. Les remarques de clôture ont souligné le rôle crucial que joue le français dans le maintien de l’écosystème de la francophonie dans le pays et ont exhorté les participants à accorder plus de valeur à la langue dans leur vie quotidienne. Les participants ont été encouragés à réfléchir à leurs contributions individuelles, que ce soit en classe, dans les domaines professionnels ou au sein de la KATF, pour assurer un avenir meilleur au français au Kenya. Le comité d’organisation a exprimé sa profonde gratitude à tous les participants, ainsi qu’à l’ambassade de France au Kenya et en Somalie, représentée par M. Bernard Clouteau, pour leur soutien et leur partenariat indéfectibles.",
    articleFiveContent:
      "Des études récentes ont mis en évidence une augmentation significative de l’intérêt des jeunes kenyans pour l’apprentissage du français, en partie grâce à l’intégration d’outils d’apprentissage numériques modernes dans les programmes scolaires. Cette tendance reflète une plus grande reconnaissance des avantages qu’offre le multilinguisme dans le monde globalisé d’aujourd’hui, notamment en termes d’opportunités de carrière et d’échanges culturels. L’intégration de plateformes d’apprentissage en ligne, d’applications linguistiques interactives et de ressources en ligne a rendu le français plus accessible aux étudiants, leur permettant d’aborder la langue de manière dynamique et personnalisée. Cet enthousiasme croissant est un indicateur positif pour l’avenir de l’enseignement du français au Kenya, signalant une croissance potentielle dans les sphères académiques et professionnelles.",
    articleSixContent:
      "Le programme de formation continue des enseignants de français, conçu pour doter les enseignants des compétences nécessaires pour enseigner efficacement le français, a récemment connu un renforcement significatif atteignant désormais plusieurs enseignants exerçant dans toutes les régions du Kenya. Cette croissance est une réponse au besoin de formation pour avoir des enseignants de français qualifiés dans les régions urbaines et rurales. En offrant une formation sur les techniques novateurs de l’enseignement ainsi sur l’intégration du numérique en classe de langue, il est prévu qu’il y aura une amélioration dans l'apprentissage de français dans les écoles et établissements d'enseignement supérieur.",
    articleLinkText: "Lire l'article",
    formNameLabel: "Votre Nom",
    formEmailLabel: "Votre E-mail",
    formPhoneLabel: "Votre Numéro de Téléphone",
    formMessageLabel: "Votre Message",
    formButtonText: "Envoyer Le Message",
    formNamePlaceholder: "entrez votre nom",
    formEmailPlaceholder: "entrez votre adresse email",
    formPhonePlaceholder: "entrez votre numéro",
    formMessagePlaceholder: "entrez votre message",
    formNameError: "Veuillez saisir un nom valide (au moins 3 lettres) !",
    formEmailError: "Veuillez saisir une adresse électronique valide !",
    formPhoneError: "Veuillez entrer un numéro de téléphone valide !",
    formMessageError: "Veuillez insérer votre message !",
    formSuccessMessage:
      "Votre message a été envoyé avec succès ! Nous vous contacterons bientôt",
    galleryCaption1:
      "Inauguration du 22ème centre de ressources au TVET de Kinyanjui",
    galleryCaption2: "La journée de révision au Lycée St. Pierre Mumias",
    galleryCaption3:
      "Les participants durant la session de la deuxième épreuve",
    galleryCaption4:
      "M. Eric Wekesa, en train d’exploiter la troisième épreuve ",
    galleryCaption5:
      "Professeure de français et  représentante de l’union des professeurs KUPPET avec Le Secrétaire General, M. Enock Webi",
    galleryCaption6: "Les deux équipes se sont réunies durant les élections",
    galleryCaption7:
      "Les candidats au poste de secrétaire-adjoint durant les élections",
    galleryCaption8:
      "Les sept membres de l'équipe qui a remporté les élections",
    galleryCaption9:
      "Journée internationale des professeurs de français à l'Alliance Française de Mombasa, le 25 novembre 2022.",
    galleryCaption10:
      "M. Luke Munyendo avec d'autres membres lors de la Journée des professeurs de français, 2022.",
    galleryCaption11:
      "M. Luke Munyendo reçoit un certificat du jury grâce aux élèves du DELF du lycée de filles de Kwale",
    footerParagraphSt1:
      "Faire valoir l’enseignement-apprentissage de la langue française au Kenya.",
    footerParagraphSt2: "Rassembler les enseignants de la langue française.",
    footerParagraphSt3: "Renforcer les compétences professionnelles.",
    footerQLHeading: "Raccourcis",
    footerLinkItem1: "KATF Accueil",
    footerLinkItem2: "À Propos de la KATF",
    footerLinkItem3: "Adhésion",
    footerLinkItem4: "Événements",
    footerLinkItem5: "Photos",
    footerLinkItem6: "Ressources",
    footerLinkItem7: "Actualités",
    footerConnectHeading: "Coordonnées",
    footerCopyright:
      "2024 La KATF Association Kényane des Professeurs de Français || Conçu et Développé par Ra’Asis.",
    // resources in french
    teachingMaterials: "Ressources pédagogiques",
    guidelines: "Directives",
    publications: "Publications",
    resourceNames: {
      rapportRevision: "Rapport sur  La Journée de Révision au Lycée St. Peters",
      rapportProfesseurs: "Rapport Enseignement Professeurs Français",
      statutEnseignant: "Le Statut de l'Enseignant au Niveau Tertiaire",
      bureauxAdministratifs: "Bureaux Administratifs de la KATF",
      webConfQuestions: "Questions Clé de La Webconférence Du 21-23 Août",
      webConfConclusion: "Conclusion de la Web Conférence",
      webConfProgramme: "Programme de la Conférence Virtuelle Tenue les 21-23 Août 2024",
      histoireElections: "Historique des Élections de la KATF",
      katfNairobi: "KATF Nairobi",
      katfSeptembre: "KATF Septembre 2024 par Bernard Clouteau",
      presentationCRKF: "Présentation CRKF",
      conference2024: "Conférence de la KATF 2024",
      strategicPlan: "Plan Strategique de la KATF",
    },
    schoolStatsHeading: "Statistiques sur les écoles",
    totalSchoolsHeading: "Nombre total des écoles",
    listofSchoolsLinkText: "Liste d'écoles",
    schoolsByRegionHeading: "Sélectionnez la région/catégorie que vous souhaitez afficher",
    schoolNameHeading: "Nom de l'école",
    schoolNoDataText: "Aucune donnée disponible pour cette région/catégorie",
    countyHeading : "Comté",
    schoolTypeHeading: "Catégorie d'école",
    schoolTypeOne: "Secondaire publique",
    schoolTypeTwo: "Primaire privée",
    schoolTypeThree: "Secondaire privée",
    schoolTypeFour: "Junior secondaire",
    schoolTypeFive: "Premier cycle du secondaire",
    schoolTypeSix: "Ecole internationale",
    schoolTypeSeven: "Primaire",
    schoolTypeEight: "Secondaire",
    schoolTypeNine: "Tertiaire / Universitaire",
    eventsSeoDescription: "Découvrez un calendrier dynamique d'événements à la KATF, y compris les conférences passées et à venir, les ateliers, les célébrations de la Journée des enseignants et les webinaires interactifs. Rejoignez-nous pour vous connecter, apprendre et grandir avec des éducateurs et des leaders d'opinion dans le domaine de la langue française.",
    newsSeoDescription: "Retrouvez les actualités de la KATF. Consultez les communiqués de presse / Événements / Articles sur thématiques variées.",
    contactSeoDescription: "Contactez l'Association kényane des professeurs de français (KATF) pour toute demande de renseignements, de collaboration ou de soutien. Nous sommes là pour connecter et aider les éducateurs et les passionnés de la langue et de la culture françaises, 24 heures sur 24, 365 jours par an.",
    gallerySeoDescription: "Parcourez la galerie des événements de la KATF pour revivre les moments inspirants des conférences, des ateliers, des célébrations de la Journée des enseignants et des webinaires. Voyez l'impact de nos événements !",
    francophonieSeoDescription: "La Francophonie au Kenya regroupe l'ensemble d’activités de coopération menées par le Service de coopération et d'action culturelle de l'Ambassade de France et ses partenaires associatifs et institutionnels.",
    webinarAltText: "Le mot « webinaire » est écrit sur une surface rose, entourée d'une souris blanche, d'un clavier et d'un pot de fleurs.",
    openingResourceAltText: "Invités assis sur le podium principal à Kinyanjui TTI lors de l'ouverture du vingt-deuxième centre de ressources au Kenya.",
    onlineTrainingAltText: "Un homme d'âge moyen assis sur une chaise avec un ordinateur portable et un écran de bureau sur un bureau devant lui, montrant une session de formation en ligne en cours avec plusieurs participants.",
    defikProjectAltText: "Un casque connecté à un appareil mobile reposant sur une surface verte. Les mots « Internet », « Business », « Education », « E-Learning », « Online Training », « Seminar » et « Technology » flottent sur l'image avec leurs ombres.",
    membershipIntroAltText: "Un groupe de personnes habillées de façon corporative et d'origines raciales différentes, probablement des membres d'une équipe, empilent leurs mains sur une table en bois.",
    eventCardAltText: " carte d'événement",
    publicationOneAltText: "Les mots « Parlez-vous français ? » sont peints en blanc sur un fond vert.",
    publicationTwoAltText: "Professeure de français et représentante de l’union des professeurs KUPPET (à gauche) avec le Secrétaire général M. Enock Webi (au milieu) (Katf, 2024)",
    publicationThreeAltText: "Une silhouette de personnes debout sur les lettres d'un mot extraordinairement grand, ELECTION.",
    publicationFourAltText: "Le mot « Méthodologie » écrit sur une table en bois avec une vieille horloge à aiguilles visible.",
    visionAltText: "Une icône représentant un œil illustre et souligne le titre de la section, « vision ».",
    missionAltText: "Une icône représentant une flèche atteignant sa cible, illustrant le titre de la section, « mission ».",
    valuesAltText: "Une icône représentant une main qui serre une pierre précieuse, illustrant le titre de la section, « valeurs ».",
    aboutMembershipAltText: "Une affiche présentant un message du trésorier national de la KATF sur les modalités d'adhésion et les avantages.",
    contactAltText: "Un jeune homme d'origine africaine sourit et indique l'emplacement du formulaire de contact sur la page du site. L'homme est illustré en train de prononcer le mot « Bonjour ».",
    schoolsByRegionSEOTitle: "Écoles par région",
    schoolsByRegionSEODescription: "Explorez les écoles qui enseignent le français comme langue par région ou par catégorie grâce à notre annuaire facile à utiliser. Trouvez des informations détaillées sur les écoles de français au Kenya, adaptées à votre lieu de résidence et à vos préférences.",
  },
};
