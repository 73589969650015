import React, {useContext, useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const IntroductionSection = () => {
  const { language } = useContext(LanguageContext);

  const images = [
    '/assets/executive_des.webp',
    '/assets/participants_des.webp',
    '/assets/kinyanjui_inaug_des.webp',
    '/assets/webi_sg_des.webp',
  ];

  const altTexts = translations[language].introImageAltTexts;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Control interval as needed

    return () => clearInterval(intervalId);
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };


    return(
        <div className="introduction-container">            
            {/* Right Section (Rotating Text) */}
            <div className="left-section">
                <RotatingHeaderText />
                <div className="intro-paragraph">
                  <p>
                    {translations[language].introductionText}
                  </p>
                </div>

                {/* Buttons Section */}
                <div className="intro-buttons">
                  <Link to="/contact" id="contact_btn" onClick={handleLinkClick}>
                    {translations[language].contactButton}
                  </Link>
                  <Link to="/about-us" id="more_about" onClick={handleLinkClick}>
                    {translations[language].aboutButton}
                  </Link>
                </div>
            </div>

            {/* Left Section (Image Carousel) */}
            <div className="right-section">
              <div className="img-container">
                <img src={images[currentImageIndex]} alt={altTexts[currentImageIndex]} />
              </div>
            </div>
        </div>
    );
}

function RotatingHeaderText(){
    const { language } = useContext(LanguageContext);

    const phrases = [
        translations[language].rotatingHeaderText[0],
        translations[language].rotatingHeaderText[1],
        translations[language].rotatingHeaderText[2],
      ];
    
      const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    
      useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 10000); // Will adjust accordingly later
    
        return () => clearInterval(intervalId);
      });
    
      return (
        <h1>
          {phrases[currentPhraseIndex]}
        </h1>
      );
}

export default IntroductionSection;
