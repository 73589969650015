import React, { useContext } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const AchievementsOverview = () => {
  const { language } = useContext(LanguageContext)

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  return (
    <>
        <div className="achievements-overview">
            <div className="achievements-intro">
                <h2>{translations[language].achievementsHeading}</h2>
            </div>
            <div className="achievements-grid-area">
                <div className="achievements-grid">
                    <div className="achievement-img">
                        <img src="/assets/webinar_color.webp" alt={translations[language].webinarAltText}/>
                    </div>
                    <div className="achievement-description">
                        <div className="achievement-desc-text">
                            <h3>{translations[language].achievementOneHeading}</h3>
                            <p>{translations[language].achievementOneDescription}</p>
                        </div>
                        <div className="achievement-explore-link">
                            <Link to="/events" onClick={handleLinkClick}>{translations[language].achievementExploreLinkText}<i className="material-symbols-sharp">trending_flat</i></Link>
                        </div>
                    </div>
                </div>
                <div className="achievements-grid">
                    <div className="achievement-img">
                        <img src="/assets/opening_resource.webp" alt={translations[language].openingResourceAltText}/>
                    </div>
                    <div className="achievement-description">
                        <div className="achievement-desc-text">
                            <h3>{translations[language].achievementTwoHeading}</h3>
                            <p>{translations[language].achievementTwoDescription}</p>
                        </div>
                        <div className="achievement-explore-link">
                            <Link to="/events/opening-kinyanjui-resource-2024" onClick={handleLinkClick}>{translations[language].achievementExploreLinkText}<i className="material-symbols-sharp">trending_flat</i></Link>
                        </div>
                    </div>
                </div>
                <div className="achievements-grid">
                    <div className="achievement-img">
                        <img src="/assets/virtual.webp" alt={translations[language].onlineTrainingAltText}/>
                    </div>
                    <div className="achievement-description">
                        <div className="achievement-desc-text">
                            <h3>{translations[language].achievementThreeHeading}</h3>
                            <p>{translations[language].achievementThreeDescription}</p>
                        </div>
                        <div className="achievement-explore-link">
                            <Link to="/events" onClick={handleLinkClick}>{translations[language].achievementExploreLinkText}<i className="material-symbols-sharp">trending_flat</i></Link>
                        </div>
                    </div>
                </div>
                <div className="achievements-grid">
                    <div className="achievement-img">
                        <img src="/assets/online_headphones.webp" alt={translations[language].defikProjectAltText}/>
                    </div>
                    <div className="achievement-description">
                        <div className="achievement-desc-text">
                            <h3>{translations[language].achievementFourHeading}</h3>
                            <p>{translations[language].achievementFourDescription}</p>
                        </div>
                        <div className="achievement-explore-link">
                            <Link to="#" onClick={handleLinkClick}>{translations[language].achievementExploreLinkText}<i className="material-symbols-sharp">trending_flat</i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default AchievementsOverview;
