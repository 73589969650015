import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const Publications = () => {
    const { language } = useContext(LanguageContext);

    const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: "User Interaction",
          action: "Clicked A Btn Link",
          label: linkText,
        });
      };

    return(
        <>
            <div className="publications-container">
                <div className="publications-intro">
                    <h2>{translations[language].publicationsHeading}</h2>
                </div>
                <div className="publications-grid">
                    <div className="publication-box">
                        <div className="publication-img">
                            <img src="/assets/french_kenya.webp" alt={translations[language].publicationOneAltText} />
                        </div>
                        <div className="publication-text">
                            <Link to="/resources" onClick={handleLinkClick}>
                                <h5>{translations[language].publicationOneTitle}<i className="material-symbols-sharp">trending_flat</i></h5>
                            </Link>
                            <p>{translations[language].publicationOneExcerpt}</p>
                        </div>
                    </div>
                    <div className="publication-box">
                        <div className="publication-img">
                            <img src="/assets/webi_publication.webp" alt={translations[language].publicationTwoAltText} />
                        </div>
                        <div className="publication-text">
                            <Link to="/resources" onClick={handleLinkClick}>
                                <h5>{translations[language].publicationTwoTitle}<i className="material-symbols-sharp">trending_flat</i></h5>
                            </Link>
                            <p>{translations[language].publicationTwoExcerpt}</p>
                        </div>
                    </div>
                    <div className="publication-box">
                        <div className="publication-img">
                            <img src="/assets/elections.webp" alt={translations[language].publicationThreeAltText} />
                        </div>
                        <div className="publication-text">
                            <Link to="/resources" onClick={handleLinkClick}>
                                <h5>{translations[language].publicationThreeTitle}<i className="material-symbols-sharp">trending_flat</i></h5>
                            </Link>
                            <p>{translations[language].publicationThreeExcerpt}</p>
                        </div>
                    </div>
                    <div className="publication-box">
                        <div className="publication-img">
                            <img src="/assets/methodology.webp" alt={translations[language].publicationFourAltText} />
                        </div>
                        <div className="publication-text">
                            <Link to="/resources" onClick={handleLinkClick}>
                                <h5>{translations[language].publicationFourTitle}<i className="material-symbols-sharp">trending_flat</i></h5>
                            </Link>
                            <p>{translations[language].publicationFourExcerpt}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Publications;