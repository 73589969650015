import React, { useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const PartnerRecognitionSection = () => {
  const { language } = useContext(LanguageContext);

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <div className="partner-recognition-section">
        <div className="partner-recognition-intro">
          <RotatingHeader />
          <p>{translations[language].partnerIntroParagraph}</p>
        </div>
        <div className="partner-recognition-images">
          <div className="partner-logos">
            <div><Link to="https://www.fipf.info/a-propos/" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><img src="/assets/fipf_logo.webp" alt="Fédération Internationale des Professeurs de Français" /></Link></div>
            <div><Link to="https://transfrontieresagencies.com" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><img src="/assets/transfro_logo.svg" alt="Transfrontières Agencies" /></Link></div>
            <div><Link to="https://raasissoftware.com" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><img src="/assets/raasis_navy_logo.webp" alt="Ra'Asis Software" /></Link></div>
            <div><Link to="https://cftd.reseau-canope.fr/" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><img src="/assets/canope_logo.webp" alt="Réseau Canopé" /></Link></div>
            <div><Link to="https://crkf.esidoc.fr/" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><img src="/assets/crkf_logo.webp" alt="CRKF" /></Link></div>
            <div><img src="/assets/francophone_net_logo.webp" alt="Francophone Network of Kenya" /></div>
            <div><img src="/assets/kicd_logo.webp" alt="Kenya Institute of Curriculum Development" /></div>
            <div><img src="/assets/uon_logo.webp" alt="University of Nairobi" /></div>
            <div><Link to="https://ke.ambafrance.org/" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><img src="/assets/ambassade_logo.webp" alt="Office of the French Ambassador" /></Link></div>
            <div><img src="/assets/alliance_logo.webp" alt="Alliance Français de Nairobi" /></div>
            <div><img src="/assets/kifaransa_logo.webp" alt="Kifaransa Village" /></div>
            <div><img src="/assets/ku_logo.webp" alt="Kenyatta University" /></div>
            <div><img src="/assets/francophonie_logo.webp" alt="Francophonie au Kenya" /></div>
          </div>
        </div>
        <div className='francophonie-link'>
          <Link to="/francophonie-au-kenya" id="events_info" onClick={handleLinkClick}>
              {translations[language].francophonieLinkText}<i className='bx bxs-hand-right'></i>
          </Link>
        </div>
    </div>
  );
}

const RotatingHeader = () => {
    const { language } = useContext(LanguageContext);

    const phrases = [
        translations[language].partnerRotatingHeadings[0],
        translations[language].partnerRotatingHeadings[1],
        translations[language].partnerRotatingHeadings[2],
      ];
    
      const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    
      useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 10000); // Will adjust accordingly later
    
        return () => clearInterval(intervalId);
      });
    
      return (
        <h2>
          {phrases[currentPhraseIndex]}
        </h2>
      );
}

export default PartnerRecognitionSection;
