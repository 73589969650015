import React, {useState, useEffect, useContext} from "react";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const Header = () => {

  const { language, toggleLanguage } = useContext(LanguageContext);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [icon, setIcon] = useState("bx bx-menu");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
    setIcon(isMenuOpen ? "bx bx-menu" : "bx bx-x");
    const newIcon = isMenuOpen ? "keyboard_arrow_down" : "keyboard_arrow_right";
    const menuItems = document.querySelectorAll(".material-symbols-sharp.right-side");
    menuItems.forEach(item => item.innerHTML = newIcon);
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
    setIcon("bx bx-menu"); // Change the icon back to the menu icon
  };

  useEffect(() => {
    const disableRightClick = () => {
      const handleRightClick = (event) => {

        // Check if the right-click event originates from an image element
        const target = event.target;
        const isImageElement = target.tagName.toLowerCase() === "img";

        // If the right-click event originates from an image element, prevent default behavior
        if (isImageElement) {
          event.preventDefault();
        } else {
          // If the right-click event originates from any other element, check for background images
          const allElements = document.querySelectorAll("*");
          allElements.forEach(element => {
            const backgroundImage = getComputedStyle(element).backgroundImage;
            if (backgroundImage.includes(".png") || backgroundImage.includes(".jpg")) {
              event.preventDefault();
            }
          });
        }
      };

      document.addEventListener("contextmenu", handleRightClick);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener("contextmenu", handleRightClick);
      };
    };

    disableRightClick();
  }, []);

  return (
    <header className="header">
        <div className="logo">
            <Link to="/">
                <img src="/assets/katf_logo.png" alt="KATF logo" />
            </Link>
        </div>
        <i className={icon} id="menu_icon" onClick={toggleMenu}></i>
        <nav className={isMenuOpen ? "active" : ""} id="main_nav">
            <ul>
                <li>
                    <Link to="/" className={location.pathname === "/" ? "active" : ""} onClick={closeMenu}>
                      <i class="bx bxs-home" id="home_icon"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/about-us" className={location.pathname === "/about-us" ? "active" : ""} onClick={closeMenu}>{translations[language].about}
                    </Link>
                </li>
                <li><Link to="/membership" className={location.pathname === "/membership" ? "active" : ""}onClick={closeMenu}>{translations[language].membership}</Link></li>
                <li>
                    <Link to="/events" className={location.pathname === "/events" ? "active" : ""} onClick={closeMenu}>{translations[language].events}
                    </Link>
                </li>
                <li><Link to="/resources" className={location.pathname === "/resources" ? "active" : ""}onClick={closeMenu}>{translations[language].resources}</Link></li>
                <li><Link to="/news" className={location.pathname === "/news" ? "active" : ""}onClick={closeMenu}>{translations[language].news}</Link></li>
                <li><Link to="/contact-us" className={location.pathname === "/contact-us" ? "active" : ""}onClick={closeMenu}>{translations[language].contact}</Link></li>
                <li><Link to="/gallery" className={location.pathname === "/gallery" ? "active" : ""}onClick={closeMenu}>{translations[language].gallery}</Link></li>
            </ul>
        </nav>
        {/* Language toggle buttons */}
        <div className="language-toggle">
          <h6>{language === "en" ? "Switch To French" : "Passer à L\"anglais"}</h6>
          <button onClick={() => toggleLanguage()}>{language === "en" ? "🇫🇷" : "🇬🇧"}</button>
        </div>
    </header>
  );
}

export default Header;
