import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { translations } from "../../data/translationsData";
import { LanguageContext } from "../../context/LanguageContext";

const PastEvents = ({ category }) => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: `${category ? category : "All Past"} Events | KATF`,
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  // Mock data for past events
  const pastEvents = [
    {
      image: "/assets/french_day.png",
      dateRange: translations[language].frenchTeachersDayDateRange,
      title: translations[language].mainEventTitle,
      category: translations[language].frenchDaysEventCategory,
      link: "/events/french-teachers-day-2024",
      categoryLabel: translations[language].frenchDaysEventCategory,
    },
    {
      image: "/assets/web_conference.png",
      dateRange: translations[language].webConferenceDateRange,
      title: translations[language].webConferenceTitle,
      category: translations[language].conferenceEventCategory,
      link: "/events/web-conference-2024",
      categoryLabel: translations[language].conferenceEventCategory,
    },
    {
      image: "/assets/kinyanjui_opening.png",
      dateRange: translations[language].openingResourceDateRange,
      title: translations[language].openingResourceTitle,
      category: translations[language].otherEventCategory,
      link: "/events/opening-kinyanjui-resource-2024",
      categoryLabel: translations[language].otherEventCategory,
    },
    {
      image: "/assets/katf_elections.png",
      dateRange: translations[language].electionsDateRange,
      title: translations[language].electionsTitle,
      category: translations[language].otherEventCategory,
      link: "/events/katf-elections-2024",
      categoryLabel: translations[language].otherEventCategory,
    },
    {
      image: "/assets/language_workshop.png",
      dateRange: translations[language].languageWorkshopDateRange,
      title: translations[language].languageWorkshopTitle,
      category: translations[language].workshopEventCategory,
      link: "/events/language-workshop-2024",
      categoryLabel: translations[language].workshopEventCategory,
    },
    {
      image: "/assets/fos_workshop.png",
      dateRange: translations[language].fosWorkshopDateRange,
      title: translations[language].fosWorkshopTitle,
      category: translations[language].workshopEventCategory,
      link: "/events/fos-workshop-2024",
      categoryLabel: translations[language].workshopEventCategory,
    },
  ];

  const filteredEvents = category ? pastEvents.filter(event => event.category === category) : pastEvents;

  return (
    <>
      <div className="list-heading">
          <h2>{translations[language].pastEvents}</h2>  
      </div>
      <div className="past-events-list">
        {filteredEvents.length > 0 ? (
          filteredEvents.map(event => (
            <div key={event.title} className="event-card">
              <div className="event-image">
                <img src={event.image} alt={event.title} />
              </div>
              <div className="event-headings">
                <h5 className="red-heading">{event.dateRange}</h5>
                <h3>{event.title}</h3>
                <Link to={event.link} onClick={handleLinkClick}>{translations[language].eventDetailsLinkText}<i className="bx bxs-hand-right"></i></Link>
                <h5 id="label">{event.categoryLabel}</h5>
              </div>
            </div>
          ))
        ) : (
          <p>No {category ? category : translations[language].pastEvents} events available.</p>
        )}
      </div>
    </>
  );
}

export default PastEvents;