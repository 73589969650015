import React, { useState, useEffect, useContext } from 'react';
import { translations } from '../data/translationsData';
import { LanguageContext } from '../context/LanguageContext';

const ExecutiveMembers = () => {
  const { language } = useContext(LanguageContext);

  const testimonialsData = [
    {
      id: 1,
      name: 'Mr. Robert Matunyo',
      position: 'Flottant',
      testimonial: translations[language].flottant2Description,
      image: 'robert_matunyo',
    },
    {
      id: 2,
      name: 'Mr. Enock Webi',
      position: translations[language].secGenPosition,
      testimonial: translations[language].secGenDescription,
      image: 'enock_webi',
    },
    {
      id: 3,
      name: 'Dr. Nuru Koki Kyalo',
      position: 'Flottant',
      testimonial: translations[language].flottant1Description,
      image: 'nuru_koki_kyalo',
    },
    {
      id: 4,
      name: 'Dr. Leonorah Ceciliah Mugalla',
      position: translations[language].presidentPosition,
      testimonial: translations[language].presidentDescription,
      image: 'leonorah_lutiali',
    },
    {
      id: 5,
      name: 'Mrs. Eshery Munala',
      position: translations[language].assSecGenPosition,
      testimonial: translations[language].assSecGenDescription,
      image: 'eshery_munala',
    },
    {
      id: 6,
      name: 'Mr. Timothy Muthoka Kyeva',
      position: translations[language].treasurerPosition,
      testimonial: translations[language].treasurerDescription,
      image: 'timothy_muthoka_kyeva',
    },
    {
      id: 7,
      name: 'Mr. Luke Munyendo',
      position: translations[language].deputyPresidentPosition,
      testimonial: translations[language].deputyPresidentDescription,
      image: 'munyendo_luke',
    },
  ];

  const [testimonials, setTestimonials] = useState(testimonialsData);
  const [selectedTestimonial, setSelectedTestimonial] = useState(testimonialsData[Math.floor(testimonialsData.length / 2)]);

  const handleTestimonialClick = (testimonial) => {
    const index = testimonials.findIndex((item) => item.id === testimonial.id);
    const newTestimonials = [...testimonials];
    const clickedTestimonial = newTestimonials.splice(index, 1)[0]; // Remove the clicked testimonial from the array
    const centerIndex = Math.floor(newTestimonials.length / 2); // Calculate the center index
    newTestimonials.splice(centerIndex, 0, clickedTestimonial); // Insert the clicked testimonial at the center

    setTestimonials(newTestimonials);
    setSelectedTestimonial(testimonial);
  };

  return (
    <div className="client-testimonials-section">
      <div className="testi-wrapper">
        <div className="client-testimonials-intro">
          <h2>{translations[language].executiveHeading}</h2>
          <p>{translations[language].executiveParagraph}</p>
        </div>
        <div className="client-testimonials">
          <div className="client-images">
            {testimonials.map((testimonial) => (
              <img
                key={testimonial.id}
                src={`/assets/${testimonial.image}.webp`}
                alt={testimonial.name}
                className={testimonial === selectedTestimonial ? 'active' : ''}
                onClick={() => handleTestimonialClick(testimonial)}
              />
            ))}
          </div>
          <div className="testimonial-content">
            <h3>{selectedTestimonial.name}</h3>
            <h4>{selectedTestimonial.position}, KATF.</h4>
            <p>{selectedTestimonial.testimonial}</p>
          </div>
        </div>
        <MobileTestimonials testimonialsData={testimonialsData} />
      </div>
    </div>
  );
};

const MobileTestimonials = ({ testimonialsData }) => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonialIndex(prevIndex =>
        prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
      );
    }, 30000);

    return () => clearInterval(intervalId);
  }, [testimonialsData]);

  return (
    <div className="mobile-testimonials">
      <img src={`/assets/${testimonialsData[currentTestimonialIndex].image}.webp`} alt={testimonialsData[currentTestimonialIndex].name} />
      <h3>{testimonialsData[currentTestimonialIndex].name}</h3>
      <h4>{testimonialsData[currentTestimonialIndex].position}, KATF.</h4>
      <p>{testimonialsData[currentTestimonialIndex].testimonial}</p>
    </div>
  );
};

export default ExecutiveMembers;