import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { translations } from "../../data/translationsData";
import { LanguageContext } from "../../context/LanguageContext";

const UpcomingEvents = ({ category }) => {

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: `${category ? category : translations[language].upcomingEvents} | KATF`,
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  // Mock data for upcoming events
  const upcomingEvents = [
    {
      image: "/assets/pedagogy_workshop.png",
      dateRange: translations[language].pedagogyWorkshopDateRange,
      title: translations[language].pedagogyWorkshopTitle,
      category: "Workshops",
      link: "/events/pedagogy-workshop-2025",
      categoryLabel: translations[language].workshopEventCategory,
    },
    {
      image: "/assets/cultural_immersion.png",
      dateRange: translations[language].culturalImmersionDateRange,
      title: translations[language].culturalImmersionTitle,
      category: translations[language].webinarEventCategory,
      link: "/events/webinar-cultural-immersion-2025",
      categoryLabel: translations[language].webinarEventCategory,
    },
    {
      image: "/assets/digital_conference.png",
      dateRange: translations[language].digitalToolsDateRange,
      title: translations[language].digitalToolsTitle,
      category: translations[language].conferenceEventCategory,
      link: "/events/digital-tools-2025",
      categoryLabel: translations[language].conferenceEventCategory,
    },
  ];

  const filteredEvents = category ? upcomingEvents.filter(event => event.category === category) : upcomingEvents;

  return (
    <>
      <div className="list-heading">
        <h2>{translations[language].upcomingEvents}</h2>
      </div>
      <div className="upcoming-events-list">
        {filteredEvents.length > 0 ? (
          filteredEvents.map(event => (
            <div key={event.title} className="event-card">
              <div className="event-image">
                <img src={event.image} alt={event.title} />
              </div>
              <div className="event-headings">
                <h5 className="red-heading">{event.dateRange}</h5>
                <h3>{event.title}</h3>
                <Link to={event.link} onClick={handleLinkClick}>{translations[language].eventDetailsLinkText}<i className="bx bxs-hand-right"></i></Link>
                <h5 id="label">{event.categoryLabel}</h5>
              </div>
            </div>
          ))
        ) : (
          <p>No {category ? category : translations[language].upcomingEvents} events available.</p>
        )}
      </div>
    </>
  );
}

export default UpcomingEvents;