import React, { useEffect, useContext, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const Contact = () => {

  const { language } = useContext(LanguageContext);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    textarea: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({
      ...formValues,
      [id]: value
    });
  };

  const validateForm = () => {
    const errors = {};
    const namePattern = /^[A-Za-z\s]{3,}$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\+?[0-9]\d{1,14}$/;

    if (!formValues.name || !namePattern.test(formValues.name)) {
      errors.name = translations[language].formNameError;
    }

    if (!formValues.email || !emailPattern.test(formValues.email)) {
      errors.email = translations[language].formEmailError;
    }

    if (!formValues.phone || !phonePattern.test(formValues.phone)) {
      errors.phone = translations[language].formPhoneError;
    }

    if (!formValues.textarea) {
      errors.textarea = translations[language].formMessageError;
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      emailjs.send("service_o9qcici", "template_p2h0njd", formValues, "o7mnX1Hx4BXz6t4rf")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage(translations[language].formSuccessMessage);
          setFormValues({ name: "", email: "", phone: "", textarea: "" });
          setTimeout(() => setSuccessMessage(""), 7000);
        }, (err) => {
          console.error("FAILED...", err);
        });
    } else {
      console.log("Form has errors.");
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: translations[language].contact + " | KATF",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  const handleButtonClick = (event) => {

    const buttonText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Button',
      label: buttonText,
    });
  };

  return (
    <>
      <Helmet>
        <title> {translations[language].contact} | KATF</title>
        <meta name="description" content={translations[language].contactSeoDescription} />
        <meta property="og:title" content={translations[language].contact + " | KATF"} />
        <meta property="og:description" content={translations[language].contactSeoDescription} />
        <meta property="og:url" content="https://katf.co.ke/contact-us" />
        <meta property="og:image" content="/assets/bonjour_contact.webp" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={translations[language].contact + " | KATF"}/>
        <meta name="twitter:description" content={translations[language].contactSeoDescription} />
      </Helmet>
      <div className="contact-container">
        <div className="contact-form">
          <form id="volu_registration" noValidate onSubmit={handleSubmit}>
            <label htmlFor="name">{translations[language].formNameLabel}</label><br />
            <input 
              type="text" 
              id="name" 
              placeholder={translations[language].formNamePlaceholder} 
              value={formValues.name}
              onChange={handleInputChange}
            /><br />
            <small id="nameError" className="error-message">{formErrors.name}</small><br /><br />

            <label htmlFor="email">{translations[language].formEmailLabel}</label><br />
            <input 
              type="email" 
              id="email" 
              placeholder={translations[language].formEmailPlaceholder} 
              value={formValues.email}
              onChange={handleInputChange}
            /><br />
            <small id="emailError" className="error-message">{formErrors.email}</small><br /><br />

            <label htmlFor="phone">{translations[language].formPhoneLabel}</label><br />
            <input 
              type="tel" 
              id="phone" 
              placeholder={translations[language].formPhonePlaceholder} 
              value={formValues.phone}
              onChange={handleInputChange}
            /><br />
            <small id="phoneError" className="error-message">{formErrors.phone}</small><br /><br />

            <label htmlFor="textarea">{translations[language].formMessageLabel}</label><br />
            <textarea 
              id="textarea" 
              placeholder={translations[language].formMessagePlaceholder} 
              value={formValues.textarea}
              onChange={handleInputChange}
            ></textarea><br />
            <small id="textAreaError" className="error-message">{formErrors.textarea}</small><br /><br />

            <button type="submit" id="submit_btn" onClick={handleButtonClick}>
              {translations[language].formButtonText} <i className="fa-solid fa-share"></i>
            </button>
          </form>
          <div className="success-message">
            {successMessage && <small>{successMessage}</small>}
          </div>
        </div>
        <div className="contact-img">
          <img src="/assets/bonjour_contact.webp" alt={translations[language].contactAltText}/>
        </div>
      </div>
      <div className="contact-socials">
          <Link to="https://www.facebook.com/associationkatf" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer"><i className="bx bxl-facebook"></i></Link>
          <Link to="https://api.whatsapp.com/send?phone=254723474929&text=Hello%20KATF%20I%20got%20your%20WhatsApp%20information%20from%20your%20website.%20I%20want%20to%20inquire%20about..." onClick={handleLinkClick} target="_blank" rel="noopener noreferrer"><i className="bx bxl-whatsapp"></i></Link>
          <Link to="https://www.instagram.com/associationkatf/" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer"><i className="bx bxl-instagram"></i></Link>
          <Link to="https://x.com/assoc_katf" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer"><i className="bx bxl-twitter"></i></Link>
          <Link to="https://linkedin.com/company/associationkatf/" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer"><i className="bx bxl-linkedin"></i></Link>
          <Link to="tel:+254723474929" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer"><i className="bx bxs-phone"></i></Link>
      </div>
    </>
  );
};

export default Contact;
