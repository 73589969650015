import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";
import UpcomingEvents from "./events/UpcomingEvents";
import PastEvents from "./events/PastEvents";

const Events = () => {
  const { language } = useContext(LanguageContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showUpcoming, setShowUpcoming] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: translations[language].events + " | KATF",
    });
  });

  const handleCategoryClick = (category) => {
    const isUpcomingOrPast = 
      category === translations[language].upcomingEvents || 
      category === translations[language].pastEvents;
  
    if (isUpcomingOrPast) {
      setShowUpcoming(category === translations[language].upcomingEvents);
      setSelectedCategory(null); // Clear specific category for all past/upcoming
    } else {
      setSelectedCategory(category); // Set specific category for filtering
      setShowUpcoming(null); // Show both Upcoming and Past for a specific category
    }
  };

  return (
    <>
      <Helmet>
        <title>{translations[language].events} | KATF</title>
        <meta name="description" content={translations[language].eventsSeoDescription} />
        <meta property="og:title" content={translations[language].events + " | KATF"}  />
        <meta property="og:description" content={translations[language].eventsSeoDescription} />
        <meta property="og:image" content="/assets/events_introbg.webp" />
        <meta property="og:url" content="https://katf.co.ke/events" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={translations[language].events + " | KATF"} />
        <meta name="twitter:description" content={translations[language].eventsSeoDescription} />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>
      <div className="events-intro">
          <h1>{translations[language].events}</h1>
      </div>
      <div className="events-main">
        <div className="event-buttons">
          {[translations[language].conferenceEventCategory, translations[language].webinarEventCategory, translations[language].workshopEventCategory, translations[language].frenchDaysEventCategory, translations[language].otherEventCategory, showUpcoming ? translations[language].pastEvents : translations[language].upcomingEvents].map(category => (
            <button key={category} onClick={() => handleCategoryClick(category)} className={selectedCategory === category ? "active" : ""}>
              {category}
            </button>
          ))}
        </div>

        <div className="event-content">
          {showUpcoming === null && selectedCategory === null ? null : 
            showUpcoming === null ? (
              <>
                <UpcomingEvents category={selectedCategory} />
                <PastEvents category={selectedCategory} />
              </>
            ) : showUpcoming ? (
              <UpcomingEvents category={selectedCategory} />
            ) : (
              <PastEvents category={selectedCategory} />
            )
          }
        </div>
      </div>
    </>
  );
}

export default Events;