import React, { useContext } from "react";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";

const MissionVisionValues = () => {
    const { language } = useContext(LanguageContext);

    return(
        <>
            <div className="mission-vision-values">
                <div className="mission-vision-value-box vision">
                    <div className="heading-icon">
                        <img src="assets/vision_transp.png" alt={translations[language].visionAltText} />
                        <h2>{translations[language].visionHeading}</h2>
                    </div>
                    <div className="box-text">
                        <p>{translations[language].visionStatement}</p>
                    </div>
                </div>
                <div className="mission-vision-value-box mission">
                    <div className="heading-icon">
                        <img src="assets/mission_transp.png" alt={translations[language].missionAltText} />
                        <h2>{translations[language].missionHeading}</h2>
                    </div>
                    <div className="box-text">
                        <p>{translations[language].missionStatement}</p>
                    </div>
                </div>
                <div className="mission-vision-value-box values">
                    <div className="heading-icon">
                        <img src="assets/values_transp.png" alt={translations[language].valuesAltText} />
                        <h2>{translations[language].valuesHeading}</h2>
                    </div>
                    <div className="box-text">
                        <p>{translations[language].valuePoints}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MissionVisionValues;