import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const TeacherNumbers = () => {

    const { language } = useContext(LanguageContext);

    return(
        <>
            <div className='teacher-numbers'>
                <div className='teacher-numbers-intro'>
                    <h2>{translations[language].teacherNumbersHeading}</h2>
                    <p>{translations[language].teacherNumbersParagraph}</p>
                </div>
                <div className='teacher-numbers-table'>
                    <table border='3' cellPadding='12' cellSpacing='3'>
                        <thead>
                            <tr>
                                <th>{translations[language].teachersTableHeading1}</th>
                                <th>{translations[language].teachersTableHeading2}</th>
                                <th>{translations[language].teachersTableHeading3}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1.</th>
                                <td>{translations[language].establishment1}</td>
                                <td>111</td>
                            </tr>
                            <tr>
                                <th>2.</th>
                                <td>{translations[language].establishment2}</td>
                                <td>404</td>
                            </tr>
                            <tr>
                                <th>3.</th>
                                <td>{translations[language].establishment3} / {translations[language].establishment4}</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <th>4.</th>
                                <td>{translations[language].establishment5}</td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <th>5.</th>
                                <td>{translations[language].establishment6}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <th></th>
                                <th>TOTAL</th>
                                <th>549</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default TeacherNumbers;