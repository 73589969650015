import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';

const FAQs = () => {
    useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: "FAQs | KATF",
        });
    });

    return(
        <>
            <Helmet>
                <title>FAQs | KATF</title>
                <meta name="description" content="We are dedicated to delivering innovative digital solutions that will significantly scale up your brand or business and streamline your operations." />
                <meta property="og:title" content="FAQs | KATF" />
                <meta property="og:description" content="Our mission is to empower businesses with cutting-edge technology and reinforce their development teams with Software Engineering expertise." />
                <meta property="og:image" content="/assets/quality_assured.png" />
                <meta property="og:url" content="https://raasissoftware.com/about-us" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="FAQs | KATF" />
                <meta name="twitter:description" content="Our mission is to empower businesses with cutting-edge technology and reinforce their development teams with Software Engineering expertise." />
                <meta name="twitter:image" content="/assets/quality_assured.png" />
                <meta name="twitter:card" content="/assets/quality_assured.png" />
            </Helmet>

            <h2>This FAQs Component Is Under Construction</h2>
        </>
    );

}

export default FAQs;