import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";
import { FaDownload } from "react-icons/fa";
import "./Resources.css";

const Resources = () => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: translations[language].resources + " | KATF",
    });
  });

  // Document sections
  const sections = [
    {
      title: translations[language].teachingMaterials,
      description:
        language === "en"
          ? "Access a variety of educational materials designed to help you learn and teach effectively."
          : "Accédez à une variété de matériels pédagogiques conçus pour vous aider à apprendre et à enseigner efficacement.",
      resources: [
        {
          name: translations[language].resourceNames.rapportRevision,
          url: "/assets/documents/RAPPORT DE LA JOURNEE DE REVISION AU LYCEE ST. PETERS.docx",
          type: "docx",
        },
        {
          name: translations[language].resourceNames.rapportProfesseurs,
          url: "/assets/documents/Rapport enseignement professeurs francais.docx",
          type: "docx",
        },
        {
          name: translations[language].resourceNames.statutEnseignant,
          url: "/assets/documents/Le statut de l'enseignant du niveau tertiaire.pptx",
          type: "pptx",
        },
        {
          name: translations[language].resourceNames.bureauxAdministratifs,
          url: "/assets/documents/BUREAUX ADMINISTRATIFS KATF.pdf",
          type: "pdf",
        },
      ],
    },
    {
      title: translations[language].guidelines,
      description:
        language === "en"
          ? "Stay informed with the latest guidelines to ensure compliance and excellence in educational and organizational practices."
          : "Restez informé avec les dernières directives pour assurer la conformité et l'excellence dans les pratiques éducatives et organisationnelles.",
      resources: [
        {
          name: translations[language].resourceNames.webConfQuestions,
          url: "/assets/documents/Questions clé de la webconférence du 21-23 aout.docx",
          type: "docx",
        },
        {
          name: translations[language].resourceNames.webConfConclusion,
          url: "/assets/documents/Conclusion de la web conférence-Discours de la présidente du Comité d'organisation.pdf",
          type: "pdf",
        },
        {
          name: translations[language].resourceNames.webConfProgramme,
          url: "/assets/documents/Programme Web-Conference KATF 21_23 AOUT 2024 FINAL.pdf",
          type: "pdf",
        },
        {
          name: translations[language].resourceNames.strategicPlan,
          url: "/assets/documents/PLAN STRATEGIQUE ASSOCIATION KENYANE POUR LES PROFESSEURS DE FRANCAIS.docx",
          type: "docx",
        },
      ],
    },
    {
      title: translations[language].publications,
      description:
        language === "en"
          ? "Browse through our research publications and reports on various educational topics and organizational achievements."
          : "Parcourez nos publications et rapports de recherche sur divers sujets éducatifs et réalisations organisationnelles.",
      resources: [
        {
          name: translations[language].resourceNames.histoireElections,
          url: "/assets/documents/KATF HISTOIRE DES ELECTIONS.pdf",
          type: "pdf",
        },
        {
          name: translations[language].resourceNames.katfNairobi,
          url: "/assets/documents/KATF NAIROBI.pdf",
          type: "pdf",
        },
        {
          name: translations[language].resourceNames.katfSeptembre,
          url: "/assets/documents/katf_sept_2024 M. Bernard Clouteau.pdf",
          type: "pdf",
        },
        {
          name: translations[language].resourceNames.presentationCRKF,
          url: "/assets/documents/PRESENTATION CRKF.pptx",
          type: "pptx",
        },
        {
          name: translations[language].resourceNames.conference2024,
          url: "/assets/documents/CONFERENCE KATF 2024.pptx",
          type: "pptx",
        },
      ],
    },
  ];

  const getImageForType = (type) => {
    switch (type) {
      case "pdf":
        return "/assets/pdf_logo.png";
      case "pptx":
        return "/assets/ppt_logo.png";
      case "doc":
      case "docx":
        return "/assets/doc_logo.png";
      default:
        return "/assets/file_logo.png";
    }
  };

  return (
    <div className="resources">
      <Helmet>
        <title>{translations[language].resources} | KATF</title>
        <meta name="description" content={translations[language].resourcesIntro} />
        <meta property="og:title" content={translations[language].resources + " | KATF"} />
        <meta property="og:description" content={translations[language].resourcesIntro}  />
        <meta property="og:image" content="/assets/resources.webp" />
        <meta property="og:url" content="https://katf.co.ke/resources" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={translations[language].resources + " | KATF"} />
        <meta name="twitter:description" content={translations[language].resourcesIntro} />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>
      <div className="resources-intro">
        <h1>{translations[language].resources}</h1>
      </div>

      <div className="resources-page">
        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="section">
            <h2 className="">{section.title}</h2>
            <p className="">{section.description}</p>

            <div className="resources-grid">
              {section.resources.map((doc, docIndex) => (
                <div key={docIndex} className="resource-item">
                  <a
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={doc.name}
                    className="mb-4"
                  >
                    <img
                      src={getImageForType(doc.type)}
                      alt={`${doc.type} logo`}
                      className="resource-icon"
                    />
                  </a>
                  <p className="doc-name">{doc.name}</p>
                  <div className="text-center">
                    <a
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={doc.name}
                      className="download-link"
                    >
                      <FaDownload className="" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resources;
