import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const Francophonie = () => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Francophonie au Kenya | KATF",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>Francophonie au Kenya | KATF</title>
        <meta name="description" content={translations[language].francophonieSeoDescription} />
        <meta property="og:title" content="Francophonie au Kenya | KATF" />
        <meta property="og:description" content={translations[language].francophonieSeoDescription} />
        <meta property="og:image" content="/assets/katf_logo.png" />
        <meta property="og:url" content="https://katf.co.ke/francophonie-au-kenya" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Francophonie au Kenya | KATF" />
        <meta name="twitter:description" content={translations[language].francophonieSeoDescription} />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>
      <div className="francophonie-section">
        <div className="francophonie-intro">
          <div className="intro-left">
            <img src="/assets/francophonie_cover.webp" alt="Francophonie au Kenya" />
          </div>
          <div className="intro-right">
            <p>{translations[language].francophonieParagraph}</p>
            <Link to="https://chat.whatsapp.com/Fj8vE5u17eyEIiB7b6ZGYg" onClick={handleLinkClick}  target="_blank" rel="noopener noreferrer">
              {translations[language].francophonieWhatsappLinkText}
            </Link>
          </div>
        </div>

        <div className="francophonie-content">
          {/* Study & Work in France */}
          <div className="content-block">
            <h2>{translations[language].francophonieHeading1}</h2>
            <div className="content-items">
              <div className="content-item">Teaching Assistant Program in France</div>
              <div className="content-item">Certifications</div>
              <div className="content-item">Campus France</div>
              <div className="content-item">Sciences Po</div>
            </div>
          </div>

          {/* French Teachers Networks */}
          <div className="content-block">
            <h2>{translations[language].francophonieHeading2}</h2>
            <div className="content-items">
              <div className="content-item" id="togreen">French Resource Centers</div>
              <div className="content-item" id="togreen">Centre for French Teaching and Documentation</div>
              <div className="content-item" id="togreen">French As A Priority</div>
              <div className="content-item" id="togreen">IFprofs Kenya</div>
              <div className="content-item" id="togreen">Kenya Association of Teachers of French</div>
            </div>
          </div>

          {/* Francophone Projects */}
          <div className="content-block">
            <h2>{translations[language].francophonieHeading3}</h2>
            <div className="content-items">
              <div className="content-item">Cycles de webinaires</div>
              <div className="content-item">Dis-moi des mots doux</div>
              <div className="content-item">Médias francophones kényans</div>
              <div className="content-item">Podcasts "Bouche à oreille"</div>
              <div className="content-item">Ciné-club "La lanterne magique"</div>
              <div className="content-item">Chaînes Youtube</div>
              <div className="content-item">Mitahato Village</div>
              <div className="content-item">French Clubs</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Francophonie;