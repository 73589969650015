import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import ExecutiveMembers from "./ExecutiveMembers";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";
import MissionVisionValues from "./MissionVisionValues";
import TeacherNumbers from "./TeacherNumbers";
import Objectives from "./Objectives";
import RegionalGoverners from "./RegionalGoverners";

const AboutUs = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: translations[language].about + " | KATF",
    });
  });

  const { language } = useContext(LanguageContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const paragraph = translations[language].aboutUsParagraph;
  const words = paragraph.split(" ");
  const wordLimit = 50;
  const displayedText = isExpanded ? paragraph : `${words.slice(0, wordLimit).join(" ")}...`;

  const structureImage = language === "en" ? "assets/structure.png" : "assets/french_structure.png";

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>{translations[language].about} | KATF</title>
        <meta name="description" content={translations[language].aboutBriefParagraph} />
        <meta property="og:title" content={translations[language].about + " | KATF"} />
        <meta property="og:description" content={translations[language].aboutBriefParagraph} />
        <meta property="og:image" content="/assets/katf_logo.png" />
        <meta property="og:url" content="https://katf.co.ke/about-us" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={translations[language].about + " | KATF"} />
        <meta name="twitter:description" content={translations[language].aboutBriefParagraph} />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>
      <div className="about-us-intro">
        <div className="rotating-heading">
          <RotatingHeadingText />
        </div>
      </div>
      <div className="about-us-detailed">
        <div className="about-detailed-content">
            <h2>{translations[language].aboutUsHeading}</h2>
            <p>
              {displayedText}
            </p>
            <button onClick={toggleExpand}>
              {isExpanded ? translations[language].aboutUsLessText : translations[language].aboutUsMoreText}
              <i className={isExpanded ? "bx bx-chevron-up" : "bx bx-chevron-down"}></i>
            </button>
        </div>
      </div>
      <MissionVisionValues />
      <Objectives />
      <div className="about-structure">
        <h2>{translations[language].structureHeading}</h2>
        <img src={structureImage} alt={translations[language.structureHeading]} />
        <p>{translations[language].structureParagraph}</p>
      </div>
      <ExecutiveMembers />
      <RegionalGoverners />
      <TeacherNumbers />
      <div className="about-outro">
        <div className="about-outro-heading">
            <h2>{translations[language].aboutOutroHeading}</h2>
        </div>
        <div className="about-outro-btn">
            <Link to="/membership" onClick={handleLinkClick}>{translations[language].aboutOutroLinkText}<i className="bx bxs-hand-right"></i></Link>
        </div>
      </div>
    </>
  );
}

const RotatingHeadingText = () => {
  const { language } = useContext(LanguageContext);

  const phrases = [
      translations[language].aboutHeadingText[0],
      translations[language].aboutHeadingText[1],
      translations[language].aboutHeadingText[2],
    ];
  
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
      }, 10000); // Will adjust accordingly later
  
      return () => clearInterval(intervalId);
    });
  
    return (
      <h1>
        {phrases[currentPhraseIndex]}
      </h1>
    );
}

export default AboutUs;
