import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";

const EventDetails = () => {
  const { language } = useContext(LanguageContext);
  const { eventId } = useParams();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: event.title + " | KATF",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  // Mock event details data
  const eventDetailsData = {
    "french-teachers-day-2024": {
      title: translations[language].mainEventTitle,
      dateRange: translations[language].frenchTeachersDayDateRange,
      location: translations[language].frenchTeachersDayLocation,
      description: translations[language].frenchTeachersDayDescription,
    },
    "pedagogy-workshop-2025": {
      title: translations[language].pedagogyWorkshopTitle,
      dateRange: translations[language].pedagogyWorkshopDateRange,
      location: translations[language].pedagogyWorkshopLocation,
      description: translations[language].pedagogyWorkshopDescription,
    },
    "webinar-cultural-immersion-2025": {
      title: translations[language].culturalImmersionTitle,
      dateRange: translations[language].culturalImmersionDateRange,
      location: null,
      description: translations[language].culturalImmersionDescription,
    },
    "digital-tools-2025": {
      title: translations[language].digitalToolsTitle,
      dateRange: translations[language].digitalToolsDateRange,
      location: translations[language].digitalToolsLocation,
      description: translations[language].digitalToolsDescription,
    },
    "web-conference-2024": {
      title: translations[language].webConferenceTitle,
      dateRange: translations[language].webConferenceDateRange,
      location: null,
      description: translations[language].webConferenceDescription,
    },
    "opening-kinyanjui-resource-2024": {
      title: translations[language].openingResourceTitle,
      dateRange: translations[language].openingResourceDateRange,
      location: translations[language].openingResourceLocation,
      description: translations[language].openingResourceDescription,
    },
    "katf-elections-2024": {
      title: translations[language].electionsTitle,
      dateRange: translations[language].electionsDateRange,
      location: translations[language].electionsLocation,
      description: translations[language].electionsDescription,
    },
    "language-workshop-2024": {
      title: translations[language].languageWorkshopTitle,
      dateRange: translations[language].languageWorkshopDateRange,
      location: translations[language].languageWorkshopLocation,
      description: translations[language].languageWorkshopDescription,
    },
    "fos-workshop-2024": {
      title: translations[language].fosWorkshopTitle,
      dateRange: translations[language].fosWorkshopDateRange,
      location: translations[language].fosWorkshopLocation,
      description: translations[language].fosWorkshopDescription,
    },
  };

  const event = eventDetailsData[eventId];

  useEffect(() => {
    if (event) {
      document.title = `${event.title} | KATF`;
    }
  }, [event]);

  if (!event) {
    return <p>{translations[language].eventNotFound}</p>;
  }

  // Special rendering for "french-teachers-day-2024" with a link
  const renderFrenchTeachersDay = () => (
    <div className="jipf-content">
      <p>
        {event.description}
      </p>
      <Link to="https://lejourdesprofs.org/" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">
        {translations[language].frenchTeachersDayLinkText}<i className="bx bxs-hand-right"></i>
      </Link>
    </div>
  );

  // Render sections and other data dynamically for other events
  const renderDescription = (description) => {
    return (
      <div className="event-details">
        {description.title && <h2>{description.title}</h2>}

        {description.sections && description.sections.map((section, index) => (
          <div key={index}>
            <h3>{section.heading}</h3>
            <br />
            <ul>
              {section.questions.map((question, qIndex) => (
                <li key={qIndex}>{question}</li>
              ))}
            </ul>
            <br />
          </div>
        ))}

        {description.conclusion && description.conclusion.map((text, index) => (
          <p key={index}>{text}</p>
        ))}

        {description.thankYou && description.thankYou.map((text, index) => (
          <p key={index}><strong>{text}</strong></p>
        ))}

        {description.closingPrayer && description.closingPrayer.map((text, index) => (
          <p key={index}><em>{text}</em></p>
        ))}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{event.title} | KATF</title>
        <meta name="description" content={event.description.substr(0, 160)} />
        <meta property="og:title" content={event.title + " | KATF"} />
        <meta property="og:description" content={event.description.substr(0, 160)} />
        <meta property="og:image" content="/assets/katf_logo.png" />
        <meta property="og:url" content="https://katf.co.ke/events/:eventId" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={event.title + " | KATF"} />
        <meta name="twitter:description" content={event.description.substr(0, 160)}  />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>
      <div className="event-details">
        <h1>{event.title}</h1>
        <h3>{event.dateRange}</h3>
        {event.location && <h4>{event.location}</h4>}
        {eventId === "french-teachers-day-2024" ? (
          renderFrenchTeachersDay()
        ) : (
          typeof event.description === "string" ? (
            <p>{event.description}</p>
          ) : (
            renderDescription(event.description)
          )
        )}
      </div>
    </>
  );
};

export default EventDetails;