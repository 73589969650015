import React, { useContext } from "react";
import ReactGA from "react-ga4";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";
import "./SchoolStats.css";
import { Link } from "react-router-dom";

const SchoolStats = () => {
  const { language } = useContext(LanguageContext);

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  const statsData = [
    { regionEn: "Central Region", regionFr: "Région Centre", count: 39 },
    { regionEn: "Coast Region", regionFr: "Région Côte", count: 57 },
    { regionEn: "Nyanza Region", regionFr: "Région Nyanza", count: 92 },
    { regionEn: "Western Region", regionFr: "Région Ouest", count: 74 },
    { regionEn: "Rift Valley North", regionFr: "Région RV Nord", count: 41 },
    { regionEn: "Rift Valley South", regionFr: "Région RV Sud", count: 29 },
    { regionEn: "Nairobi Region", regionFr: "Région Nairobi", count: 51 },
    { regionEn: "South Eastern Region", regionFr: "Région Sud Est", count: 21 },
    { regionEn: "Primary/Private", regionFr: "Primaire/Privée", count: 111 },
    { regionEn: "Teacher Training College", regionFr: "TTC", count: 14 },
    { regionEn: "TVET/Polytechnic", regionFr: "TTI/TVET/Polytec", count: 20 },
    { regionEn: "University", regionFr: "Université", count: "N/A" },
  ];

  const totalSchools = 549;

  return (
    <div className="stats-container">
      <div className="stats-header">
        <h2>
          {translations[language].schoolStatsHeading}
        </h2>
      </div>

      <div className="cards-container">
        {statsData.map((stat, index) => (
          <div className="stat-card" key={index}>
            <h4>{language === "en" ? stat.regionEn : stat.regionFr}</h4>
            <p>({stat.count})</p>
          </div>
        ))}
      </div>

      <div className="total">
        <h3>
          {translations[language].totalSchoolsHeading} (
          {totalSchools})
        </h3>
      </div>

      <Link to="/schools-by-region" onClick={handleLinkClick}>
        {translations[language].listofSchoolsLinkText}
      </Link>
    </div>
  );
};

export default SchoolStats;
