import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";

const Gallery = () => {
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: translations[language].gallery + " | KATF",
        });
    });

    return(
        <>
            <Helmet>
                <title>{translations[language].gallery} | KATF</title>
                <meta name="description" content={translations[language].gallerySeoDescription} />
                <meta property="og:title" content={translations[language].gallery + " | KATF"} />
                <meta property="og:description" content={translations[language].gallerySeoDescription} />
                <meta property="og:image" content="/assets/katf_logo.png" />
                <meta property="og:url" content="https://katf.co.ke/gallery" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content={translations[language].gallery + " | KATF"} />
                <meta name="twitter:description" content={translations[language].gallerySeoDescription}/>
                <meta name="twitter:image" content="/assets/katf_logo.png" />
                <meta name="twitter:card" content="/assets/katf_logo.png" />
            </Helmet>

            <div className="site-gallery">
                <div className="image-box">
                    <img src="/assets/kinyanjui_inaug.webp" alt={translations[language].galleryCaption1} />
                    <p>{translations[language].galleryCaption1}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/opening_resource1.webp" alt={translations[language].galleryCaption1} />
                    <p>{translations[language].galleryCaption1}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/opening_resource5.webp" alt={translations[language].galleryCaption1} />
                    <p>{translations[language].galleryCaption1}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/opening_resource3.webp" alt={translations[language].galleryCaption1} />
                    <p>{translations[language].galleryCaption1}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/opening_resource2.webp" alt={translations[language].galleryCaption1} />
                    <p>{translations[language].galleryCaption1}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/president_west.webp" alt={translations[language].galleryCaption2} />
                    <p>{translations[language].galleryCaption2}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/participants.webp" alt={translations[language].galleryCaption3} />
                    <p>{translations[language].galleryCaption3}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/speaker_eric.webp" alt={translations[language].galleryCaption4} />
                    <p>{translations[language].galleryCaption4}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/webi_sg.webp" alt={translations[language].galleryCaption2} />
                    <p>{translations[language].galleryCaption2}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/contestants_lunch.webp" alt={translations[language].galleryCaption6} />
                    <p>{translations[language].galleryCaption6}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/secretaires_adjoints.webp" alt={translations[language].galleryCaption7} />
                    <p>{translations[language].galleryCaption7}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/executive.webp" alt={translations[language].galleryCaption8} />
                    <p>{translations[language].galleryCaption8}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/luke_cert.jpg" alt={translations[language].galleryCaption9} />
                    <p>{translations[language].galleryCaption9}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/luke_group.jpeg" alt={translations[language].galleryCaption10} />
                    <p>{translations[language].galleryCaption10}</p>
                </div>
                <div className="image-box">
                    <img src="/assets/luke_delf_cert.jpg" alt={translations[language].galleryCaption11} />
                    <p>{translations[language].galleryCaption11}</p>
                </div>
            </div>
        </>
    );

}

export default Gallery;