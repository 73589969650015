import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const Objectives = () => {
  const { language } = useContext(LanguageContext);
  
  return (
    <div className="objectives-container">
      <div className="objectives-intro">
        <i className="bx bxs-briefcase-alt-2"></i>
        <h2>{translations[language].objectivesHeading}</h2>
      </div>
      <div className="objectives-list">
        {translations[language].objectives.map((objective, index) => (
          <p key={index} className="objective-item">
            <i class="bx bxs-bookmark-star"></i>{objective}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Objectives;
